import { useEffect, useMemo } from 'react';

import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { useChangeThreadsMutation, useGetThreadsChangeOptionsQuery } from 'store/api';
import type { AxiosBaseQueryError } from 'store/base';
import type { Option } from 'types';
import { ToastManager } from 'utils/toast';

import { ChangeThreadsForm, type ChangeThreadsValues } from './ChangeThreadsForm';

export type ChangeThreadsModalProps = {
  proxyIds: number[];
};

export function ChangeThreadsModal({ proxyIds }: ChangeThreadsModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();

  const [changeThreads] = useChangeThreadsMutation();
  const {
    data: threadsOptions,
    isFetching: isOptionsLoading,
    refetch,
  } = useGetThreadsChangeOptionsQuery(proxyIds[0], {
    skip: proxyIds.length > 1,
  });

  const options = useMemo<Option[]>(() => {
    return threadsOptions?.options.map((i) => ({ value: i, label: i === 0 ? t('common:unlimited') : String(i) })) ?? [];
  }, [t, threadsOptions?.options]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Formik<ChangeThreadsValues>
      initialValues={{ threads: threadsOptions?.currentThreads ?? 100 }}
      enableReinitialize
      onSubmit={async ({ threads }) => {
        try {
          await changeThreads({ proxyId: proxyIds[0], threads }).unwrap();

          ToastManager.success(t('proxies.modals.upgradeThreads.success'));
          hideModal();
        } catch (error) {
          const err = error as AxiosBaseQueryError;

          if (err.errors && typeof err.errors === 'string') {
            ToastManager.error(t(`errors:${err.errors}`));
          } else {
            ToastManager.error(t('proxies.modals.upgradeThreads.failure'));
          }
        }
      }}
    >
      <ChangeThreadsForm options={options} isLoading={isOptionsLoading} />
    </Formik>
  );
}

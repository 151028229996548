import { useEffect, useMemo } from 'react';

import Grid from '@mui/material/Grid';

import { Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Button } from 'components/Button';
import { Autocomplete } from 'components/form-partials/Autocomplete';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { posthogEvents } from 'constants/posthogEvents';
import { getValidationErrors } from 'hooks/useRequestError';
import {
  useGetProxyProtocolQuery,
  useUpdateProxyProtocolBulkMutation,
  useUpdateProxyProtocolMutation,
} from 'store/api';
import type { AxiosBaseQueryError } from 'store/base';
import type { ProxyModel } from 'store/proxies/models';
import { Protocol } from 'store/proxies/types';
import type { Option } from 'types';
import { ToastManager } from 'utils/toast';

import { MultiUpdatesAccordion } from '../MultiUpdatesAccordion';

export type ChangeProxyProtocolModalProps = {
  proxies: ProxyModel[];
};

type ChangeProxyProtocolValues = {
  protocol: Protocol | string;
};

const schema: Yup.Schema<ChangeProxyProtocolValues> = Yup.object().shape({
  protocol: Yup.string().oneOf(Object.values(Protocol)).required(),
});

export function ChangeProxyProtocolModal({ proxies = [] }: ChangeProxyProtocolModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [updateProtocolSingle] = useUpdateProxyProtocolMutation();
  const [updateProtocolBulk] = useUpdateProxyProtocolBulkMutation();
  const proxyIds = useMemo(() => proxies.map(({ id }) => id), [proxies]);
  const { data, refetch, isUninitialized } = useGetProxyProtocolQuery(proxyIds[0]);

  const hideModal = useHideModal();

  const initialValues = useMemo<ChangeProxyProtocolValues>(() => {
    if (!data || proxies.length > 1) return { protocol: '' };

    return { protocol: data.currentType };
  }, [data, proxies]);

  const options = useMemo<Option[]>(() => {
    if (!data) return [];

    return data.availableTypes.map((item) => ({ label: item, value: item }));
  }, [data]);

  useEffect(() => {
    if (isUninitialized) return;

    refetch();
  }, [isUninitialized, refetch]);

  return (
    <Formik<ChangeProxyProtocolValues>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={async ({ protocol }, { setErrors, setSubmitting }) => {
        try {
          if (proxyIds.length > 1) {
            await updateProtocolBulk({ ids: proxyIds, protocol }).unwrap();

            posthog?.capture(posthogEvents.proxy.bulk.changeProtocol.success, {
              proxyIds,
              total: proxyIds.length,
            });
          } else {
            await updateProtocolSingle({ newType: protocol, proxyId: proxyIds[0] }).unwrap();

            posthog?.capture(posthogEvents.proxy.changeProtocol.success, { proxyId: proxyIds[0], protocol });
          }

          ToastManager.success(t('proxies.modals.changeProtocol.success'));
          hideModal();
        } catch (ex) {
          const validationErrors = getValidationErrors(ex as AxiosBaseQueryError);

          if (validationErrors && Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
          }

          if (proxyIds.length > 1) {
            posthog?.capture(posthogEvents.proxy.bulk.changeProtocol.failed, {
              proxyIds,
              total: proxyIds.length,
            });
          } else {
            posthog?.capture(posthogEvents.proxy.changeProtocol.failed, { proxyId: proxyIds[0], protocol });
          }

          setSubmitting(false);
        }
      }}
      validationSchema={schema}
      validateOnMount
      validateOnChange
    >
      {({ handleSubmit, isValid, dirty, isSubmitting }) => {
        const actions = [
          <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
            {t('common:buttons.cancel')}
          </Button>,
          <Button key="update" type="submit" fullWidth loading={isSubmitting} disabled={!isValid || !dirty}>
            {t('common:buttons.change')}
          </Button>,
        ];

        return (
          <ModalContainer
            component="form"
            containerProps={{ onSubmit: handleSubmit, noValidate: true }}
            title={t('proxies.modals.changeProtocol.title')}
            subtitle={t('proxies.modals.changeProtocol.subtitle')}
            actions={actions}
          >
            <Grid container spacing={4}>
              {proxyIds.length > 1 && (
                <Grid item xs={12}>
                  <MultiUpdatesAccordion proxyIds={proxyIds} />{' '}
                </Grid>
              )}

              <Grid item xs={12}>
                <Autocomplete fullWidth name="protocol" options={options} />
              </Grid>
            </Grid>
          </ModalContainer>
        );
      }}
    </Formik>
  );
}

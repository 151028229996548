import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getOrderStep } from 'modules/orders/helpers';
import { useAppDispatch } from 'store';
import { resetOrderFlow, setCurrentStep } from 'store/orders/reducers';

import Styled from './styled';
import { useOrderNavigationItems } from '../hooks/useOrderNavigationItems';

export function OrderLayoutNavigation() {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const items = useOrderNavigationItems();

  const isItemEnabled = useCallback(
    (path: string) => {
      const currentPath = location.pathname;
      const orderPaths = items.map((p) => p.path);

      const currentPathIndex = orderPaths.findIndex((p) => p === currentPath);

      return orderPaths.slice(0, currentPathIndex).includes(path);
    },
    [items, location.pathname],
  );

  const handleItemClick = useCallback(
    (path: string) => {
      const step = getOrderStep(path);

      // Reset order flow for any other order step
      if (!step) dispatch(resetOrderFlow({ shouldResetCouponCode: false }));

      dispatch(setCurrentStep(step));
    },
    [dispatch],
  );

  return (
    <>
      {items.map(({ label, path }) => {
        const isActive = path === location.pathname;

        return (
          <Styled.OrderLayoutNavigationItemRoot
            key={label}
            labelVariant={isActive ? 'subtitle' : 'body-2'}
            labelProps={{ color: isActive ? 'text.primary' : 'text.secondary' }}
            $isActive={isActive}
            to={{ pathname: path, search: location.search }}
            disabled={!isItemEnabled(path)}
            onClick={() => handleItemClick(path)}
          >
            {t(label)}
          </Styled.OrderLayoutNavigationItemRoot>
        );
      })}
    </>
  );
}

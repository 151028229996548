import { useState } from 'react';

import { Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useGetUserSessionQuery, useUpgradeThreadsMutation } from 'store/api';
import type { AxiosBaseQueryError } from 'store/base';
import { ToastManager } from 'utils/toast';

import { UpgradeThreadsForm, type UpgradeThreadsFormProps } from './UpgradeThreadsForm';

export type UpgradeThreadsModalProps = Pick<UpgradeThreadsFormProps, 'proxyIds'>;

export function UpgradeThreadsModal({ proxyIds }: UpgradeThreadsModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [upgradeThreads] = useUpgradeThreadsMutation();
  const hideModal = useHideModal();
  const { data: session } = useGetUserSessionQuery();
  const [singleItemPrice, setSingleItemPrice] = useState(0);

  return (
    <Formik
      initialValues={{ threads: 100 }}
      onSubmit={async ({ threads }) => {
        try {
          await upgradeThreads({ proxyId: proxyIds[0], threads }).unwrap();

          GA_EVENTS.proxyThreadsUpgraded({
            userId: session?.userId,
            value: singleItemPrice,
            proxy_id: proxyIds[0],
            threads,
          });

          posthog?.capture(posthogEvents.proxy.upgradeThreads.success, { proxyId: proxyIds[0], threads });

          ToastManager.success(t('proxies.modals.upgradeThreads.success'));
          hideModal();
        } catch (error) {
          const err = error as AxiosBaseQueryError;

          if (err.errors && typeof err.errors === 'string') {
            ToastManager.error(t(`errors:${err.errors}`));
          } else {
            ToastManager.error(t('proxies.modals.upgradeThreads.failure'));
          }

          posthog?.capture(posthogEvents.proxy.upgradeThreads.failed, { proxyId: proxyIds[0], threads });
        }
      }}
    >
      <UpgradeThreadsForm proxyIds={proxyIds} price={singleItemPrice} setPriceForSingle={setSingleItemPrice} />
    </Formik>
  );
}

import { useMemo } from 'react';

import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { posthogEvents } from 'constants/posthogEvents';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useVPN } from 'hooks/useVPN';
import { paths } from 'paths';
import { useAppSelector } from 'store';
import { buildLegacyAppURL } from 'utils/url';
import { isAdminUser } from 'utils/user';

import type { NavItemConfig } from './types';

export function useNavigationItems() {
  const { t } = useTranslation();
  const { accessToken, refreshToken } = useAppSelector((state) => state.auth);
  const posthog = usePostHog();

  const { shouldDisplayVPN } = useVPN();
  const { isDealsInventoryEnabled } = useFeatureFlag();
  const isVPNPromotionEnabled = useFeatureFlagEnabled('vpn-promotion');

  return useMemo<Record<string, NavItemConfig[]>>(() => {
    return {
      general: [
        { title: 'overview', href: paths.index, icon: 'overview' },
        { title: 'myProxies', href: paths.proxies.index, icon: 'my-proxies' },
        { title: 'vpn-legacy', href: paths.vpns.index, icon: 'vpns', disabled: !shouldDisplayVPN },
        {
          title: 'marketplace',
          href: paths.marketplace.index,
          icon: 'shop',
          disabled: !isDealsInventoryEnabled,
        },
        {
          title: 'vpn',
          icon: 'vpns',
          href: process.env.REACT_APP_VPN_CHEAP_APP_URL,
          disabled: !isVPNPromotionEnabled,
          label: t('common:new'),
          labelColor: 'error',
          onClick: (e) => {
            e.preventDefault();

            posthog.capture(posthogEvents.common.switchedToVPNCheap);

            window.location.href = `${process.env.REACT_APP_VPN_CHEAP_APP_URL}?access_token=${accessToken}&refresh_token=${refreshToken}`;
          },
        },
      ],
      partnership: [
        { title: 'referrals', href: paths.referrals.index, icon: 'dollar' },
        { title: 'reseller', href: paths.reseller.index, icon: 'tag' },
      ],
      developer: [
        { title: 'apiKeys', href: paths.apiKeys.index, icon: 'code' },
        { title: 'apiDocumentation', href: paths.external.apiDocumentation, icon: 'api', external: true },
      ],
      tools: [
        { title: 'whatIsMyIp', href: paths.external.whatIsMyIp, icon: 'what-is-my-ip', external: true },
        { title: 'needHelp', href: paths.external.needHelp, icon: 'need-help', external: true },
      ],

      ...(isAdminUser() && {
        admin: [
          {
            title: 'adminPanel',
            href: buildLegacyAppURL('admin/users'),
            icon: 'refresh',
            onClick: (e) => {
              e.preventDefault();

              window.location.href = buildLegacyAppURL('admin/users');
            },
          },
        ],
      }),
    };
  }, [accessToken, isDealsInventoryEnabled, isVPNPromotionEnabled, posthog, refreshToken, shouldDisplayVPN, t]);
}

import { useMemo, useState } from 'react';

import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import type { InputLabelProps } from '@mui/material/InputLabel';

import { Field, getIn, useFormikContext } from 'formik';
import type { TextFieldProps } from 'formik-mui';

import { Icon } from 'components/Icon';
import type { IconName } from 'components/Icon/types';

import Styled from './styled';

export type InputProps = Omit<TextFieldProps, 'id' | 'form' | 'meta' | 'field' | 'placeholder'> & {
  value?: string;
  name: string;
  fullWidth?: boolean;
  startIcon?: IconName;
  endIcon?: IconName;
  inputLabelProps?: InputLabelProps;
  placeholder?: string | null;
};

export function Input({
  name,
  value: propValue,
  type,
  label,
  endIcon,
  startIcon,
  InputProps: FormInputProps,
  size = 'medium',
  inputLabelProps,
  ...props
}: InputProps) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { values } = useFormikContext<Record<string, unknown>>();
  const value = propValue != null ? propValue : getIn(values, name);

  const handleClickShowPassword = () => setIsPasswordVisible((prevIsPasswordVisible) => !prevIsPasswordVisible);

  const adornment = useMemo(() => {
    if (type === 'password') {
      return (
        <InputAdornment position="end">
          <Styled.AdornmentIcon
            data-testid="password-input-adornment-button"
            onClick={handleClickShowPassword}
            onMouseDown={(e) => e.preventDefault()}
          >
            <Icon name={isPasswordVisible ? 'eye-slash' : 'eye'} size="small" />
          </Styled.AdornmentIcon>
        </InputAdornment>
      );
    }

    if (endIcon) {
      return (
        <InputAdornment position="end">
          <Icon name={endIcon} size="small" />
        </InputAdornment>
      );
    }

    if (FormInputProps?.endAdornment) {
      return <InputAdornment position="end">{FormInputProps.endAdornment}</InputAdornment>;
    }

    if (startIcon) {
      return (
        <InputAdornment position="start">
          <Icon name={startIcon} size="small" />
        </InputAdornment>
      );
    }

    if (FormInputProps?.startAdornment) {
      return <InputAdornment position="start">{FormInputProps.startAdornment}</InputAdornment>;
    }

    return null;
  }, [type, FormInputProps, startIcon, endIcon, isPasswordVisible]);

  const getInputType = () => {
    if (type === 'password') {
      return isPasswordVisible ? 'text' : 'password';
    }

    return type;
  };

  const shouldRenderEndAdornment = useMemo(
    () => !!endIcon || !!FormInputProps?.endAdornment || type === 'password',
    [FormInputProps, endIcon, type],
  );

  const shouldRenderStartAdornment = useMemo(
    () => !!startIcon || !!FormInputProps?.startAdornment,
    [startIcon, FormInputProps],
  );

  return (
    <Styled.Container fullWidth={props.fullWidth}>
      {label && <FormLabel id={`label-for-${name}`}>{label}</FormLabel>}

      <Field
        {...props}
        id={name}
        name={name}
        aria-labelledby={`label-for-${name}`}
        value={value}
        type={getInputType()}
        component={Styled.Input}
        size={size}
        InputProps={{
          endAdornment: shouldRenderEndAdornment && adornment,
          startAdornment: shouldRenderStartAdornment && adornment,
        }}
      />
    </Styled.Container>
  );
}

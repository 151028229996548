import type { AppEndpointBuilder } from 'store/base';

import { toExperimentModel, toFeaturesModel } from './helpers';
import type { ExperimentModel, FeaturesModel } from './models';

export const commonEndpoints = (builder: AppEndpointBuilder) => ({
  // #region Queries
  getExperiments: builder.query<ExperimentModel[], void>({
    query: () => ({
      url: 'experiments',
      method: 'GET',
    }),
    providesTags: (result) =>
      result
        ? [...result.map(({ id }) => ({ type: 'Experiments' as const, id })), { type: 'Experiments', id: 'LIST' }]
        : [{ type: 'Experiments', id: 'LIST' }],
    transformResponse: toExperimentModel,
  }),

  getFeatures: builder.query<FeaturesModel, void>({
    query: () => ({
      url: 'features',
      method: 'GET',
    }),
    providesTags: ['Features'],
    transformResponse: toFeaturesModel,
  }),
  // #endregion Quries
});

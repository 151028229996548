import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { useHideModal } from 'components/modals/context';
import { useRouter } from 'hooks/useRouter';
import { useShowModal } from 'modules/common/GlobalModals';
import { useAppDispatch } from 'store';
import { resetOrderFlow } from 'store/orders/reducers';

export function useShowUnavailableDeals() {
  const { t } = useTranslation();
  const showModal = useShowModal();
  const hideModal = useHideModal();
  const router = useRouter();
  const dispatch = useAppDispatch();

  return useDebouncedCallback((error: unknown) => {
    if ((error as { errors?: unknown }).errors === 'DEAL_NOT_FOUND') {
      showModal('order.no-deals', {
        title: t('common:order.modals.noDeals.title'),
        subtitle: t('common:order.modals.noDeals.subtitle'),
        closeDisabled: true,
        onConfirm: () => {
          hideModal();
          dispatch(resetOrderFlow());
          router.back();
        },
        confirmLabel: t('common:buttons.quit'),
      });
    }
  }, 500);
}

import { useEffect, useMemo } from 'react';

import dayjs from 'dayjs';
import { matchPath, useLocation } from 'react-router-dom';

import { paths } from 'paths';
import { useGetIsProxyReplacementAvailableQuery, useGetMaintenanceStatusQuery } from 'store/api';

export function useMaintenance() {
  const { pathname } = useLocation();

  const {
    data: maintenance,
    fulfilledTimeStamp: maintenanceFulfilledTimeStamp,
    refetch: getMaintenance,
  } = useGetMaintenanceStatusQuery();
  const {
    data: isProxyReplacementAvailable,
    fulfilledTimeStamp: replacementAvailabilityFulfilledTimeStamp,
    refetch: getReplacementAvailability,
  } = useGetIsProxyReplacementAvailableQuery();

  const shouldDisplayMaintenanceBanner = useMemo(() => {
    if (!maintenance) return false;

    if (!maintenance.end || !maintenance.start) return false;

    return true;
  }, [maintenance]);

  const shouldDisplayBanner = useMemo(() => {
    return shouldDisplayMaintenanceBanner || !!isProxyReplacementAvailable;
  }, [isProxyReplacementAvailable, shouldDisplayMaintenanceBanner]);

  const shouldRefetchMaintenanceWindow = useMemo(() => {
    const fiveMinutesAhead = dayjs(maintenanceFulfilledTimeStamp).add(5, 'minutes');

    const isTimeElapsed = dayjs().isAfter(fiveMinutesAhead);

    return (
      isTimeElapsed &&
      [paths.proxies.index, `${paths.proxies.index}/:proxyId`, paths.index].some((value) => matchPath(value, pathname))
    );
  }, [maintenanceFulfilledTimeStamp, pathname]);

  const shouldRefetchReplacementAvailability = useMemo(() => {
    const fiveMinutesAhead = dayjs(replacementAvailabilityFulfilledTimeStamp).add(5, 'minutes');

    const isTimeElapsed = dayjs().isAfter(fiveMinutesAhead);

    return (
      isTimeElapsed &&
      [paths.proxies.index, `${paths.proxies.index}/:proxyId`, paths.index].some((value) => matchPath(value, pathname))
    );
  }, [pathname, replacementAvailabilityFulfilledTimeStamp]);

  useEffect(() => {
    if (!shouldRefetchMaintenanceWindow) return;

    getMaintenance();
  }, [getMaintenance, shouldRefetchMaintenanceWindow]);

  useEffect(() => {
    if (!shouldRefetchReplacementAvailability) return;

    getReplacementAvailability();
  }, [getReplacementAvailability, shouldRefetchReplacementAvailability]);

  return useMemo(
    () => ({ shouldDisplayBanner, maintenance, isProxyReplacementAvailable }),
    [isProxyReplacementAvailable, maintenance, shouldDisplayBanner],
  );
}

import { type ReactNode, useCallback, useEffect, useMemo } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import type { IconProps } from 'components/Icon/types';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { useCountdown } from 'hooks/useCountdown';
import { paths } from 'paths';
import { useAppDispatch } from 'store';
import { resetOrderFlow } from 'store/orders/reducers';
import { OrderStatus } from 'store/orders/types';
import { ToastManager } from 'utils/toast';

import Styled from './styled';
import { useGetOrder } from './useGetOrder';

type Variant = {
  isLoader?: boolean;
  isCounterVisible?: boolean;
  icon?: IconProps;
  title?: string | null;
  subtitle?: string | null;
  action?: ReactNode;
};

export type OrderCheckoutStatusModalProps = {
  orderId: string;
  isVPN: boolean;
  onClose?: () => void;
};

export function OrderCheckoutStatusModal({ orderId, isVPN, onClose }: OrderCheckoutStatusModalProps) {
  const { t } = useTranslation();
  const hideModal = useHideModal();
  const { data = { id: '', status: OrderStatus.PAYING } } = useGetOrder(orderId);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();

  const [
    orderProcessCounter,
    isOrderCounterFinished,
    { startCountdown: startOrderProcessCountdown, stopCountdown: stopOrderProcessCountdown },
  ] = useCountdown({ delay: 60 });
  const [redirectCounter, isRedirectCounterFinished, { startCountdown: startRedirectCounter }] = useCountdown({
    delay: 5,
  });

  const shouldAllowToCancelModal = useCallback(
    (reason: 'backdropClick' | 'escapeKeyDown') => {
      return reason === 'backdropClick' && data.status !== OrderStatus.FAILED && data.status !== OrderStatus.FULFILLED;
    },
    [data.status],
  );

  const onModalClose = useCallback(
    (_: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (shouldAllowToCancelModal(reason)) {
        return;
      }

      return hideModal();
    },
    [hideModal, shouldAllowToCancelModal],
  );

  const handleClose = useCallback(
    (isSuccess = false, shouldDisplayMessage = false) => {
      if (!isSuccess) {
        onClose?.();

        return hideModal();
      }

      if (shouldDisplayMessage) {
        ToastManager.success(
          t('account.modals.order.success', {
            service: t(`common:services.${isVPN ? 'vpn' : 'proxy'}`),
          }),
        );
      }

      navigate({ pathname: isVPN ? paths.vpns.index : paths.proxies.index, search });
      // wait navigation to be applied
      setTimeout(() => {
        dispatch(resetOrderFlow());
      }, 200);

      return hideModal();
    },
    [dispatch, hideModal, isVPN, navigate, onClose, search, t],
  );

  const { subtitle, title, action, icon, isLoader, isCounterVisible } = useMemo<Variant>(() => {
    if (data.status === OrderStatus.FAILED) {
      return {
        icon: { name: 'close-circle', color: 'error' },
        title: t('order.modals.status.failure.title'),
        subtitle: t('order.modals.status.failure.subtitle'),
        action: (
          <Button fullWidth onClick={() => handleClose()}>
            {t('order.modals.status.failure.action')}
          </Button>
        ),
      };
    }

    if (data.status === OrderStatus.FULFILLED) {
      return {
        icon: { name: 'tick-circle', color: 'success' },
        title: t('order.modals.status.success.title'),
        subtitle: t('order.modals.status.success.subtitle'),
        action: (
          <Button fullWidth onClick={() => handleClose(true)}>
            {t('order.modals.status.success.action', { counter: redirectCounter })}
          </Button>
        ),
      };
    }

    if (data.status === OrderStatus.PAYING) {
      return { isLoader: true, subtitle: t('account.modals.status.topUp.pending.subtitle') };
    }

    return {
      isLoader: true,
      isCounterVisible: true,
      title: t('order.modals.status.pending.title'),
      subtitle: t('order.modals.status.pending.subtitle'),
    };
  }, [data, handleClose, redirectCounter, t]);

  useEffect(() => {
    if (data.status !== OrderStatus.FULFILLED) {
      return;
    }

    stopOrderProcessCountdown();
    startRedirectCounter();
  }, [data.status, startRedirectCounter, stopOrderProcessCountdown]);

  useEffect(() => {
    if (!isCounterVisible) return;

    startOrderProcessCountdown();
  }, [isCounterVisible, startOrderProcessCountdown]);

  useEffect(() => {
    if (!isRedirectCounterFinished) return;

    handleClose(true, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRedirectCounterFinished]);

  useEffect(() => {
    if (!isOrderCounterFinished) return;

    handleClose(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOrderCounterFinished]);

  return (
    <ModalContainer disableEscapeKeyDown onClose={onModalClose}>
      <Grid container direction="column" rowSpacing={4}>
        <Grid item>
          <Grid container direction="column" rowSpacing={1.5} alignItems="center">
            <Grid item>
              {icon && <Icon size={40} {...icon} />}
              {isLoader && (
                <Styled.LoaderWrapper>
                  <CircularProgress size={64} />

                  {isCounterVisible && (
                    <Styled.CounterWrapper>
                      <Typography variant="headline-2" color="primary.main">
                        {orderProcessCounter}
                      </Typography>
                    </Styled.CounterWrapper>
                  )}
                </Styled.LoaderWrapper>
              )}
            </Grid>

            {(title || subtitle) && (
              <Grid item>
                <Grid container direction="column" textAlign="center" rowSpacing={1}>
                  {title && (
                    <Grid item component={Typography} variant="headline-2">
                      {title}
                    </Grid>
                  )}

                  {subtitle && (
                    <Grid item component={Typography} variant="body-1" color="grey.500">
                      {subtitle}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        {action && <Grid item>{action}</Grid>}
      </Grid>
    </ModalContainer>
  );
}

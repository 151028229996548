import { useCallback, useMemo } from 'react';

import { PaymentOption } from 'modules/billing/types';
import { useGetFeaturesQuery } from 'store/api';
import type { FeaturesDTO } from 'store/common/dtos';

const PAYMENT_OPTION_TO_FEATURE_FLAG: Record<PaymentOption, keyof FeaturesDTO | boolean> = {
  [PaymentOption.ALIPAY]: 'isAlipayEnabled',
  [PaymentOption.BLIK]: 'isBlikEnabled',
  [PaymentOption.CRYPTO]: 'isCoingateEnabled',
  [PaymentOption.GIROPAY]: 'isGiropayEnabled',
  [PaymentOption.IDEAL]: 'isIdealEnabled',
  [PaymentOption.P24]: 'isP24Enabled',
  [PaymentOption.PAYPAL]: 'isPayPalEnabled',
  [PaymentOption.SOFORT]: 'isSofortEnabled',
  [PaymentOption.STRIPE]: true,
  [PaymentOption.WALLET]: true,
};

export function useFeatureFlag() {
  const { data, isLoading } = useGetFeaturesQuery();

  const isPaymentMethodEnabled = useCallback(
    (paymentType: PaymentOption) => {
      const item = PAYMENT_OPTION_TO_FEATURE_FLAG[paymentType];

      if (typeof item === 'boolean') {
        return item;
      }

      return !!data?.[item];
    },
    [data],
  );

  const isOrderingEnabled = useMemo(() => !!data?.isOrderingEnabled, [data?.isOrderingEnabled]);

  const isBuyingThreadsUpgradesEnabled = useMemo(
    () => !!data?.isBuyingThreadsUpgradesEnabled,
    [data?.isBuyingThreadsUpgradesEnabled],
  );

  const isBuyingUplinkUpgradesEnabled = useMemo(
    () => !!data?.isBuyingUplinkUpgradesEnabled,
    [data?.isBuyingUplinkUpgradesEnabled],
  );

  const isDealsInventoryEnabled = useMemo(() => !!data?.isDealsInventoryEnabled, [data?.isDealsInventoryEnabled]);

  const isIdentityVerificationEnabled = useMemo(
    () => !!data?.isIdentityVerificationEnabled,
    [data?.isIdentityVerificationEnabled],
  );

  const isHttpsProtocolEnabled = useMemo(() => !!data?.isHttpsProtocolEnabled, [data?.isHttpsProtocolEnabled]);

  const isLegacyVpnSupportEnabled = useMemo(() => !!data?.isLegacyVpnSupportEnabled, [data?.isLegacyVpnSupportEnabled]);

  const isLegacyMobileProxiesEnabled = useMemo(
    () => !!data?.isLegacyMobileProxiesEnabled,
    [data?.isLegacyMobileProxiesEnabled],
  );

  return useMemo(
    () => ({
      isFeatureFlagsLoading: isLoading,
      isLegacyVpnSupportEnabled,
      isPaymentMethodEnabled,
      isOrderingEnabled,
      isBuyingThreadsUpgradesEnabled,
      isBuyingUplinkUpgradesEnabled,
      isDealsInventoryEnabled,
      isIdentityVerificationEnabled,
      isHttpsProtocolEnabled,
      isLegacyMobileProxiesEnabled,
      flags: data,
    }),
    [
      data,
      isBuyingThreadsUpgradesEnabled,
      isBuyingUplinkUpgradesEnabled,
      isDealsInventoryEnabled,
      isHttpsProtocolEnabled,
      isIdentityVerificationEnabled,
      isLegacyMobileProxiesEnabled,
      isLegacyVpnSupportEnabled,
      isLoading,
      isOrderingEnabled,
      isPaymentMethodEnabled,
    ],
  );
}

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { Field, Formik } from 'formik';
import { Switch } from 'formik-mui';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { useHideModal } from 'components/modals/context';
import { ModalContainer } from 'components/modals/ModalContainer';
import { posthogEvents } from 'constants/posthogEvents';
import {
  useDisableProxyAutoExtendMutation,
  useEnableProxyAutoExtendMutation,
  useGetProxyOverviewQuery,
} from 'store/api';
import type { AxiosBaseQueryError } from 'store/base';
import { ToastManager } from 'utils/toast';

import Styled from './styled';

export type ProxyAutoExtendModalProps = {
  proxyId: number;
};

type AutoExtendValues = {
  isAutoExtendEnabled: boolean;
};

export function ProxyAutoExtendModal({ proxyId }: ProxyAutoExtendModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [enableAutoExtend] = useEnableProxyAutoExtendMutation();
  const [disableAutoExtend] = useDisableProxyAutoExtendMutation();
  const hideModal = useHideModal();
  const { data: proxy, isLoading } = useGetProxyOverviewQuery(proxyId);

  return (
    <Formik<AutoExtendValues>
      initialValues={{
        isAutoExtendEnabled: proxy?.autoExtendEnabled || false,
      }}
      enableReinitialize
      onSubmit={async ({ isAutoExtendEnabled }) => {
        try {
          if (isAutoExtendEnabled) {
            await enableAutoExtend({ proxyId }).unwrap();
            posthog?.capture(posthogEvents.proxy.autoExtend.enabled.success, { proxyId });

            ToastManager.success(t('proxies.modals.autoExtend.success.enabled'));
          } else {
            await disableAutoExtend({ proxyId }).unwrap();
            posthog?.capture(posthogEvents.proxy.autoExtend.disabled.success, { proxyId });

            ToastManager.success(t('proxies.modals.autoExtend.success.disabled'));
          }

          hideModal();
        } catch (error) {
          const err = error as AxiosBaseQueryError;

          if (err.errors && typeof err.errors === 'string') {
            ToastManager.error(t(`errors:${err.errors}`));
          } else {
            ToastManager.error(t('proxies.modals.extendPeriod.failure'));
          }

          if (isAutoExtendEnabled) {
            posthog?.capture(posthogEvents.proxy.autoExtend.enabled.failed, { proxyId });
          } else {
            posthog?.capture(posthogEvents.proxy.autoExtend.disabled.failed, { proxyId });
          }
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <ModalContainer
          component="form"
          containerProps={{ onSubmit: handleSubmit, noValidate: true }}
          title={t('proxies.modals.autoExtend.title')}
          subtitle={t('proxies.modals.autoExtend.subtitle')}
          actions={[
            <Button key="cancel" color="secondary" fullWidth onClick={hideModal}>
              {t('common:buttons.cancel')}
            </Button>,
            <Button key="verify" type="submit" fullWidth disabled={isLoading} loading={isSubmitting}>
              {t('common:buttons.confirm')}
            </Button>,
          ]}
        >
          <Styled.PriceContainer>
            <Typography variant="title">{t('common:form.isAutoExtendEnabled')}</Typography>
            {isLoading ? (
              <Skeleton width={40} height={24} />
            ) : (
              <Field component={Switch} disabled={isSubmitting} type="checkbox" name="isAutoExtendEnabled" />
            )}
          </Styled.PriceContainer>
        </ModalContainer>
      )}
    </Formik>
  );
}

import AppBar, { type AppBarProps } from '@mui/material/AppBar';
import Badge, { badgeClasses } from '@mui/material/Badge';
import Box, { type BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

export type ContainerProps = {
  offset?: number;
  shouldDisplayAlertBanner?: boolean;
};

const APP_BAR_HEIGHT = 64;

const DashboardLayoutRoot = styled(({ shouldDisplayAlertBanner, offset, ...props }: BoxProps & ContainerProps) => (
  <Box {...props} />
))(({ shouldDisplayAlertBanner, offset = 0, theme }) => {
  return {
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: APP_BAR_HEIGHT,

    ...(shouldDisplayAlertBanner && {
      paddingTop: APP_BAR_HEIGHT + offset,
      transition: theme.transitions.create('paddingTop'),
    }),

    [theme.breakpoints.up('md')]: {
      paddingLeft: 280,
    },
  };
});

const DashboardLayoutContentRoot = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,

  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
  position: 'relative',
  gap: theme.spacing(2),

  paddingBottom: theme.spacing(8),
}));

const AccountButtonWrapper = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  padding: theme.spacing(2),
}));

const DashboardNavbarRoot = styled(({ shouldDisplayAlertBanner, offset, ...props }: AppBarProps & ContainerProps) => (
  <AppBar {...props} />
))(({ shouldDisplayAlertBanner, offset = 0, theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: `0px 1px 0px ${theme.palette.divider}`,

  ...(shouldDisplayAlertBanner && {
    transition: theme.transitions.create('top'),
    top: offset,
  }),

  [theme.breakpoints.up('md')]: {
    left: 280,
    width: 'calc(100% - 280px)',
  },
}));

const DashboardNavbarToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: APP_BAR_HEIGHT,
  left: 0,
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0),
  },
}));

const FloatingButtonBadge = styled(Badge)({
  [`& .${badgeClasses.badge}`]: {
    transform: 'none',
    top: 2,
  },
});

export default {
  DashboardNavbarRoot,
  DashboardNavbarToolbar,
  DashboardLayoutRoot,
  DashboardLayoutContentRoot,
  AccountButtonWrapper,
  FloatingButtonBadge,
};

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { RouterLink } from 'components/RouterLink';
import { paths } from 'paths';

export function ReplacementBanner() {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={2}
      bgcolor="#080A0C"
      p={3}
      borderRadius={4}
      alignItems="center"
      // TODO: Move it to mx and mt props once Stack will start using flexGap instead margin...
      style={{ marginTop: '16px', marginLeft: '16px', marginRight: '16px' }}
    >
      <Icon name="danger-dark" size={24} />

      <Stack spacing={1} textAlign="center">
        <Typography variant="title" color="error.main">
          {t('common:replacement.title')}
        </Typography>

        <Typography variant="body2" color="#DFE3E6">
          {t('common:replacement.subtitle')}
        </Typography>
      </Stack>

      <Button component={RouterLink} href={paths.proxies.replace} size="medium">
        {t('common:replacement.action')}
      </Button>
    </Stack>
  );
}

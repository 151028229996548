import { useMemo } from 'react';

import { useGetProxiesListQuery, useGetUserVPNQuery } from 'store/api';

export function useIsNewCustomer() {
  const { data: proxies } = useGetProxiesListQuery({ page: 1, perPage: 10 });
  const { data: vpn } = useGetUserVPNQuery();

  return useMemo(() => proxies?.data.length === 0 || !vpn, [proxies?.data.length, vpn]);
}

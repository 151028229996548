import { useRef, useState } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { Icon } from 'components/Icon';
import { Logo } from 'components/Logo';

import { AppSwitchMenu } from './AppSwitchMenu';

export function LogoButton() {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <>
      <Box
        ref={anchorRef}
        display="inline-flex"
        p={2}
        maxHeight={64}
        justifyContent="space-between"
        boxShadow={(theme) => `0 1px 0px ${theme.palette.divider}`}
        onClick={() => setIsPopoverOpen(true)}
      >
        <Logo wide />

        <IconButton>
          <Icon name="arrow-up-down" size="large" />
        </IconButton>
      </Box>

      <AppSwitchMenu anchorEl={anchorRef.current} open={isPopoverOpen} onClose={() => setIsPopoverOpen(false)} />
    </>
  );
}

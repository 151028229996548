import { useEffect } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { usePostHog } from 'posthog-js/react';
import { Trans, useTranslation } from 'react-i18next';

import type { CardProps } from 'components/Card';
import { TextButton } from 'components/TextButton';
import { posthogEvents } from 'constants/posthogEvents';
import { useEmailVerification } from 'hooks/useEmailVerification';
import type { ITheme } from 'theme';

import Styled from './styled';

type EmailVerificationBannerProps = Omit<CardProps, 'type'> & {
  vertical?: boolean;
  isOrderRequired?: boolean;
};

export function EmailVerificationBanner({ vertical, isOrderRequired, ...props }: EmailVerificationBannerProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();

  const isMobileDevice = useMediaQuery<ITheme>((theme) => theme.breakpoints.down('md'));

  const { isEmailVerificationRequired, session, handleResendVerificationEmail } = useEmailVerification(true);
  const verticalLayout = vertical || isMobileDevice;

  useEffect(() => {
    if (isEmailVerificationRequired && session?.profileDetails?.email && isOrderRequired) {
      posthog?.capture(posthogEvents.email.confirmation.displayed, { email: session.profileDetails.email });
    }
  }, [posthog, isEmailVerificationRequired, session?.profileDetails?.email, isOrderRequired]);

  return (
    <Styled.Container
      type={isOrderRequired ? 'info' : 'contrast'}
      size={verticalLayout ? 'small' : 'large'}
      direction={verticalLayout ? 'vertical' : 'horizontal'}
      verticalLayout={verticalLayout}
      {...props}
    >
      <Stack
        spacing={verticalLayout ? 2 : 4}
        direction={verticalLayout ? 'column' : 'row'}
        alignItems={verticalLayout ? 'center' : undefined}
      >
        <Styled.NewEmailIcon light={isOrderRequired} />
        <Stack spacing={1} direction="column" justifyContent="center" textAlign={verticalLayout ? 'center' : undefined}>
          <Typography variant="title" color={isOrderRequired ? undefined : 'common.white'}>
            {t('order.banners.emailVerification.title')}
          </Typography>
          <Typography variant="body-2" color={isOrderRequired ? undefined : 'grey.400'}>
            {isOrderRequired ? (
              <Trans i18nKey="order.banners.emailVerification.subtitleOrder">
                To complete the order, please verify your email. We've sent a verification link to
                {session?.profileDetails?.email}. Didn't receive an email?{' '}
                <TextButton
                  id="verification-email-resend"
                  onClick={handleResendVerificationEmail}
                  labelVariant="body-2"
                  labelProps={{ color: 'primary.main' }}
                >
                  Resend
                </TextButton>
              </Trans>
            ) : (
              <Trans i18nKey="order.banners.emailVerification.subtitle">
                We've sent a verification link to {session?.profileDetails?.email}
                Didn't receive an email?{' '}
                <TextButton
                  id="verification-email-resend"
                  onClick={handleResendVerificationEmail}
                  labelVariant="body-2"
                  labelProps={{ color: 'primary.main' }}
                >
                  Resend
                </TextButton>
              </Trans>
            )}
          </Typography>
        </Stack>
      </Stack>
    </Styled.Container>
  );
}

import { styled } from '@mui/material/styles';
import MuiTooltip, { tooltipClasses, type TooltipProps as MuiTooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

type TooltipProps = Omit<MuiTooltipProps, 'title'> & {
  title: string;
};

export const Tooltip = styled(({ title, className, ...props }: TooltipProps) => (
  <MuiTooltip
    {...props}
    title={<Typography variant="body-2">{title}</Typography>}
    classes={{ popper: className }}
    enterTouchDelay={0}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.text.secondary,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      backgroundColor: theme.palette.text.secondary,
    },
  },
}));

import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from 'components/Button';
import { Logo } from 'components/Logo';
import { useEmailVerification } from 'hooks/useEmailVerification';
import { useIDVerificationStatus } from 'hooks/useIDVerificationStatus';
import { AccountSuspendedBanner } from 'modules/accounts/AccountSuspendedBanner';
import { useBlackFridayExperiment } from 'modules/common/hooks';
import { EmailVerificationBanner } from 'modules/orders/banners/EmailVerificationBanner';
import { OrderProxyButton } from 'modules/orders/OrderProxyButton';
import { paths } from 'paths';
import { useGetUserSessionQuery } from 'store/api';
import type { ITheme } from 'theme';
import { PersistService } from 'utils/persist';

import { Balance } from './Balance';
import { LogoButton } from './LogoButton';
import { NavItem } from './NavItem';
import Styled from './styled';
import { useNavigationItems } from './useNavigationItems';
import { IDVerificationBanner } from '../../IDVerificationBanner';

type SidebarProps = {
  isOpen: boolean;
  shouldDisplayAlertBanner: boolean;
  offset?: number;
  onClose: () => void;
};

export function Sidebar({ shouldDisplayAlertBanner, isOpen, offset, onClose }: SidebarProps) {
  const { t } = useTranslation();
  const isMobileDevice = useMediaQuery<ITheme>((theme) => theme.breakpoints.down('md'));

  const isVPNPromotionEnabled = useFeatureFlagEnabled('vpn-promotion');

  const { data: session } = useGetUserSessionQuery();
  const { shouldDisplayIDNotification } = useIDVerificationStatus();
  const { isEmailVerificationRequired } = useEmailVerification();

  const navItems = useNavigationItems();

  const shouldDisplaySoftLogout = useMemo(() => !!PersistService.getImpersonatedUser(), []);

  const { isBlackFridayAllowedForUser, isExperimentLoading } = useBlackFridayExperiment();

  return (
    <Styled.Sidebar
      anchor="left"
      open={isMobileDevice ? isOpen : true}
      offset={offset}
      onClose={onClose}
      shouldDisplayAlertBanner={shouldDisplayAlertBanner}
      variant={isMobileDevice ? 'temporary' : 'permanent'}
    >
      <Stack flex="1 1 auto" useFlexGap>
        {!isVPNPromotionEnabled ? (
          <Box
            display="inline-flex"
            p={2}
            component={Link}
            to={paths.index}
            onClick={onClose}
            maxHeight={64}
            boxShadow={(theme) => `0 1px 0px ${theme.palette.divider}`}
          >
            <Logo wide />
          </Box>
        ) : (
          <LogoButton />
        )}

        <Stack spacing={4} p={2} useFlexGap>
          <Stack spacing={2} useFlexGap>
            <Collapse in={session?.profileDetails?.isSuspended} unmountOnExit>
              <AccountSuspendedBanner />
            </Collapse>

            <Collapse in={shouldDisplayIDNotification} unmountOnExit>
              <IDVerificationBanner />
            </Collapse>

            <Collapse in={isEmailVerificationRequired} unmountOnExit>
              <EmailVerificationBanner vertical />
            </Collapse>

            <Balance />

            <OrderProxyButton
              size="large"
              fullWidth
              isBlackFridayAllowedForUser={isBlackFridayAllowedForUser}
              isExperimentLoading={isExperimentLoading}
            />
          </Stack>

          <Stack component="nav" spacing={4} flex="1 1 auto" useFlexGap>
            {Object.entries(navItems).map(([key, group]) => (
              <Stack spacing={1} key={key} useFlexGap>
                <Typography variant="badge">{t(`common:sidebar.${key}`)}</Typography>

                <Stack component="ul" sx={{ listStyle: 'none', m: 0, p: 0 }} useFlexGap>
                  {group.map(({ title, ...config }) => (
                    <NavItem key={title} title={title} onClose={onClose} {...config} />
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>

          {shouldDisplaySoftLogout && (
            <Button
              fullWidth
              color="secondary"
              startIcon="logout"
              slotProps={{ startIcon: { size: 'large' } }}
              onClick={() => {
                PersistService.remove('X-Switch-User');

                window.location.href = paths.index;
              }}
            >
              {t('common:buttons.resetToMyUser')}
            </Button>
          )}
        </Stack>
      </Stack>
    </Styled.Sidebar>
  );
}

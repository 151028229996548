import isNil from 'lodash/isNil';

import { COUNTRIES } from 'constants/countries';
import type { Currency, PaymentOption } from 'modules/billing/types';
import { AuthenticationType, IPVersion, NetworkType, ServiceType } from 'store/proxies/types';
import type { Option } from 'types';
import { toOption } from 'utils/mappers';
import { parseTimePeriod } from 'utils/values';

import type {
  BaseOrderConfigurationPayload,
  OrderConfigurationPayload,
  OrderConfigurationResponse,
  OrderDTO,
  OrderExecutePayload,
  OrderPriceResponse,
  SupportedCountry,
  SupportedISP,
} from './dtos';
import type { OrderConfigurationModel, OrderModel, OrderPriceModel, ProxySetupModel } from './models';
import type { ISPOption, ToSupportedTimePeriodModelArgs } from './types';
import { isValidIpV4 } from './validation';

// #region Helper mappers

/**
 * Creates array of country options from given array of strings
 *
 * @param {Array<SupportedCountry>} from
 * @returns {Option|null}
 */
export function toSupportedCountriesModel(from: SupportedCountry[]): Option[] {
  return from
    .map(({ amount, code }) => {
      const country = COUNTRIES.find((c) => c.value === code);

      if (!country) return null;

      return { ...country, subLabel: amount ?? 'common:outOfStock' };
    })
    .filter((val) => !isNil(val)) as Option[];
}

/**
 * Creates map with regions as Option
 *
 * @param {Array<unknown> | Record<string, Array<string>>} from
 * @returns {Record<string, Array<Option>>}
 */
function toSupportedRegionsModel(from: unknown[] | Record<string, string[]>): Record<string, Option[]> {
  if (Array.isArray(from)) return {};

  return Object.entries(from).reduce<Record<string, Option[]>>(
    (acc, [key, regions]) => ({
      ...acc,
      [key.toUpperCase()]: regions.map((code) => ({
        label: !code.length ? 'countriesregions:regions.any' : `countriesregions:regions.${code}`,
        value: !code.length ? 'any' : code,
      })),
    }),
    {},
  );
}

/**
 * Creates an array of ISP options from given ISP map
 *
 * @param {Array<unknown> | Record<string, Record<string, string>>} from
 * @returns {Array<ISPOption>}
 */
export function toSupportedISPsModel(from: [] | Record<string, [] | Record<string, SupportedISP>>): ISPOption[] {
  if (Array.isArray(from)) return [];

  return Object.entries(from)
    .filter(([, data]) => !Array.isArray(data))
    .map(([country, data]) => {
      return Object.entries(data).map(([id, { amount, name }]) => ({
        id,
        country,
        name,
        amount: amount ?? 'common:outOfStock',
      }));
    })
    .flat() as ISPOption[];
}

/**
 * Creates an array of Time Period options from given months and days
 *
 * @param {ToSupportedTimePeriodModelArgs} options
 * @returns {Array<Option>}
 */
function toSupportedTimePeriodModel(options: ToSupportedTimePeriodModelArgs): Option[] {
  const { fromDays, fromMonths, supportsSettingDays, supportsSettingMonths } = options;

  const daysOptions = fromDays.choices
    ? Array(fromDays.choices.length)
        .fill('')
        .map((_, i) => ({
          label: fromDays.choices[i] === 7 ? 'common:trial.weekTrial' : 'common:form.day',
          value: `${fromDays.choices[i]}d`,
        }))
    : [];

  const monthOptions = Array(fromMonths.max)
    .fill('')
    .map((_, i) => ({ label: 'common:form.month', value: `${i + 1}m` }));

  // No options available
  if (!supportsSettingDays && !supportsSettingMonths) return [];

  // Only days + 1 month
  if (!supportsSettingMonths && supportsSettingDays)
    return [...daysOptions, { label: 'common:form.month', value: '1m' }];

  // Only months
  if (supportsSettingMonths && !supportsSettingDays) return [...monthOptions];

  // Both options available
  return [...daysOptions, ...monthOptions];
}

/**
 * Converts NetworkType to ServiceType
 *
 * @param {NetworkType} from
 * @returns {ServiceType}
 */
export function toServiceType(from: NetworkType): ServiceType {
  switch (from) {
    case NetworkType.Mobile:
      return ServiceType.PROXY_MOBILE;
    case NetworkType.Residential:
      return ServiceType.PROXY_RESIDENTIAL;
    case NetworkType.ResidentialStatic:
      return ServiceType.PROXY_RESIDENTIAL_STATIC;
    case NetworkType.Datacenter:
      return ServiceType.PROXY_DATACENTER;
    case NetworkType.VPN:
      return ServiceType.VPN;
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`${from} is not supported`);
  }
}

/**
 * Converts ServiceType to NetworkType
 * @param {ServiceType} from
 * @returns {NetworkType}
 */
export function toNetworkType(from: ServiceType): NetworkType {
  switch (from) {
    case ServiceType.PROXY_MOBILE:
      return NetworkType.Mobile;
    case ServiceType.PROXY_RESIDENTIAL:
      return NetworkType.Residential;
    case ServiceType.PROXY_RESIDENTIAL_STATIC:
      return NetworkType.ResidentialStatic;
    case ServiceType.PROXY_DATACENTER:
      return NetworkType.Datacenter;
    case ServiceType.VPN:
      return NetworkType.VPN;
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`${from} is not supported`);
  }
}

/**
 * Converts time period to months or days
 * @param {string|null} from
 * @returns {{days: number} | {months: number}}
 */
export function formatTimePeriod(from: string | null): { months: number } | { days: number } {
  const parsedTimePeriod = parseTimePeriod(from);

  if (!parsedTimePeriod) return { months: 1 };

  const { format, value } = parsedTimePeriod;

  return format === 'days' ? { days: value } : { months: value };
}

// #endregion

// #region Response to Model mappers

/**
 * Converts order configuration response to order configuration model
 *
 * @param {OrderConfigurationResponse} from
 * @returns {OrderConfigurationModel}
 */
export function toOrderConfigurationModel(from: OrderConfigurationResponse): OrderConfigurationModel {
  const {
    supportedAuthenticationTypes,
    supportedCountries,
    supportedISPs,
    supportedIpVersions,
    supportedPackages,
    supportedProtocols,
    supportedRegions,
    supportedDays,
    supportedMonths,
    supportsSettingDays,
    supportsSettingMonths,
    ...config
  } = from;

  return {
    ...config,
    supportsSettingDays,
    supportsSettingMonths,
    supportedMonths,

    supportedDays: supportedDays.choices.map((choice) => choice) ?? [],
    supportedCountries: toSupportedCountriesModel(supportedCountries),
    supportedRegions: toSupportedRegionsModel(supportedRegions),
    supportedAuthenticationTypes: supportedAuthenticationTypes.map(toOption),
    supportedIpVersions: supportedIpVersions.map(toOption),
    supportedProtocols: supportedProtocols.map(toOption),
    supportedISPs: toSupportedISPsModel(supportedISPs),
    supportedPackages: supportedPackages.map(toOption),
    supportedTimePeriod: toSupportedTimePeriodModel({
      fromDays: supportedDays,
      fromMonths: supportedMonths,
      supportsSettingDays,
      supportsSettingMonths,
    }),
  };
}

/**
 * Converts order configuration response to order configuration model
 *
 * @param {OrderPriceResponse} from
 * @returns {OrderPriceModel}
 */
export function toOrderPriceModel(from: OrderPriceResponse): OrderPriceModel {
  return {
    discount: from.discount,

    finalPrice: 'price' in from ? (from.price ?? null) : (from.finalPrice ?? null),

    subtotal: 'price' in from ? (from.price ?? 0) : (from.subtotal ?? 0),

    paymentFee: 'price' in from ? (from.price ?? 0) : (from.paymentFee ?? 0),

    discountAmount: 'price' in from ? (from.price ?? 0) : (from.discountAmount ?? 0),

    priceNoDiscounts: 'price' in from ? (from.price ?? 0) : (from.priceNoDiscounts ?? 0),

    unitPrice: 'price' in from ? (from.price ?? 0) : (from.unitPrice ?? 0),
    // ! Stripe requires to obtaining an amount higher than 0 otherwise throws an error

    finalPriceInCurrency: 'price' in from ? (from.price ?? 1) : (from.finalPriceInCurrency ?? from.finalPrice ?? 1),

    currency: 'price' in from ? 'usd' : ((from.currency ?? 'USD').toLowerCase() as Currency),
  };
}

/**
 * Converts order response to order model
 *
 * @param {OrderDTO} from
 * @returns {OrderModel}
 */
export function toOrderModel(from: OrderDTO): OrderModel {
  return {
    id: from.id,
    status: from.status,
  };
}

// #endregion

// #region Model to Payload mappers

/**
 * Converts proxy setup model to order configuration payload
 *
 * @param {ProxySetupModel} from
 * @returns {OrderConfigurationPayload}
 */
export function toOrderConfigurationPayload(
  from: Partial<ProxySetupModel>,
  isLegacyMobileProxiesEnabled: boolean,
): OrderConfigurationPayload {
  if (!from.networkType) {
    throw new Error('Network type is a required property to get the Order Configuration Payload');
  }

  const ipWhitelist = from.ipWhitelist?.filter(isValidIpV4);
  const serviceType = toServiceType(from.networkType);

  const baseConfig: BaseOrderConfigurationPayload = {
    ipVersion: from.ipVersion || '',
    proxyProtocol: from.proxyProtocol || '',
    authenticationType: from.authenticationType || '',
    ipWhitelist: from.authenticationType === AuthenticationType.IP_WHITELIST ? ipWhitelist : undefined,
    couponCode: from.couponCode || '',
    isAutoExtendEnabled: !!from.isAutoExtendEnabled,
    isp: from.isp,
    isUnusedProxy: Boolean(from.isUnusedProxy),
    ...(from.timePeriod ? formatTimePeriod(from.timePeriod) : undefined),
  };

  if (serviceType === ServiceType.PROXY_DATACENTER) {
    return {
      ...baseConfig,
      serviceType,
      country: from.country,
      quantity: baseConfig.ipVersion === IPVersion.IPv4 ? from.quantity : undefined,
      package: baseConfig.ipVersion === IPVersion.IPv6 ? from.package?.toString() : undefined,
    };
  }

  if (serviceType === ServiceType.PROXY_MOBILE && isLegacyMobileProxiesEnabled) {
    return {
      ...baseConfig,
      serviceType,
      country: from.country,
      region: from.region === 'any' ? undefined : from.region || undefined,
      quantity: from.quantity || 1,
    };
  }

  if (serviceType === ServiceType.PROXY_MOBILE && !isLegacyMobileProxiesEnabled) {
    return {
      ...baseConfig,
      serviceType,
      bandwidth: from.bandwidth || 5,
      autoExtendBandwidth: from.autoExtendBandwidth || 10,
      quantity: from.quantity || 1,
    };
  }

  if (serviceType === ServiceType.PROXY_RESIDENTIAL) {
    return {
      ...baseConfig,
      serviceType,
      bandwidth: from.bandwidth || 5,
      autoExtendBandwidth: from.autoExtendBandwidth || 10,
      quantity: from.quantity || 1,
    };
  }

  if (serviceType === ServiceType.VPN) {
    return {
      ...baseConfig,
      serviceType,
      quantity: from.quantity || 1,
    };
  }

  return { ...baseConfig, country: from.country, serviceType, quantity: from.quantity || 1 };
}

/**
 * Converts proxy setup model to order configuration payload
 *
 * @param {ProxySetupModel} from
 * @param {string} paymentMethodMetadata
 * @param {PaymentOption|undefined} paymentMethod
 * @returns {OrderExecutePayload}
 */
export function toOrderExecutePayload(
  from: ProxySetupModel,
  paymentMethod?: PaymentOption,
  paymentMethodMetadata?: string | null,
  isLegacyMobileProxiesEnabled?: boolean,
): OrderExecutePayload {
  return {
    ...toOrderConfigurationPayload(from, !!isLegacyMobileProxiesEnabled),
    paymentMethod,
    paymentMethodMetadata: paymentMethodMetadata ?? undefined,
  };
}

// #endregion

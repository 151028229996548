import type { SupportedLanguage } from 'modules/common/Language/types';

import type { MultiFactorHardwareOptions, MultiFactorProvider, UserRole } from './types';

/**
 * @GET /captcha
 */
export type CaptchaModel = {
  isEnabled: boolean;
  siteKey: string | null;
};

/**
 * @POST /login-check
 */
export type SignInModel = {
  email: string;
  password: string;
  recaptchaToken?: string;
};

/**
 * @POST /registration
 */
export type SignUpModel = {
  email: string;
  password: string;
  recaptchaToken?: string;
  locale: string;
};

/**
 * @POST /login-check
 * @POST /registration
 */
export type TokenModel = {
  accessToken: string;
  refreshToken: string;
};

/**
 * @POST /login-check
 * @GET /2fa-status
 */
export type MultiFactorModel = {
  providers: MultiFactorProvider[];
  preferredProvider: MultiFactorProvider;
  options: MultiFactorHardwareOptions | null;
};

/**
 * @POST /login-check
 */
export type AuthenticationModel = TokenModel & {
  mfa: MultiFactorModel | null;
};

/**
 * @POST /forgot-password-post
 */
export type ForgotPasswordModel = {
  email: string;
};

/**
 * @POST /2fa-check-api
 */
export type MultiFactorPayloadModel = {
  code: string;
  preferProvider: MultiFactorModel['preferredProvider'];
};

/**
 * @GET /session
 */
export type SessionModel = {
  userId: number;
  userIp: string | null;
  language: SupportedLanguage | null;
  profileDetails?: ProfileDetailsModel;
  roles?: UserRole[];
  trialCount: number;
  trialLimit: number;
};

export enum VerificationStatus {
  NOT_VERIFIED = 'NOT_VERIFIED',
  PENDING = 'PENDING',
  REQUIRED = 'REQUIRED',
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
}

export type VerificationRequestModel = {
  status: VerificationStatus;
  reason: string | null;
};

export type ProfileDetailsModel = {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  isActive: boolean;
  valid: boolean;
  isSuspended: boolean;
  suspensionReason?: string | null;
  suspensionReasonExplanation?: string | null;
  verificationRequest: VerificationRequestModel;
  isProfileSkipped: boolean;
};

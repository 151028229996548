import { useEffect, useState } from 'react';

import { Formik } from 'formik';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { useHideModal } from 'components/modals/context';
import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { SUPPORTED_VPN_MONTHS } from 'modules/orders/OrderVPNPage/consts';
import { useExtendVPNPeriodMutation, useGetOrderPriceMutation, useGetUserSessionQuery } from 'store/api';
import type { AxiosBaseQueryError } from 'store/base';
import { toOrderConfigurationPayload } from 'store/orders/helpers';
import type { OrderPriceModel } from 'store/orders/models';
import { NetworkType } from 'store/proxies/types';
import type { VPNModel } from 'store/vpns/models';
import { ToastManager } from 'utils/toast';

import { ExtendVPNPeriodForm } from './ExtendVPNPeriodForm';

export type ExtendVPNPeriodModalProps = {
  vpnId: VPNModel['id'];
};

export function ExtendVPNPeriodModal({ vpnId }: ExtendVPNPeriodModalProps) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [extendPeriod] = useExtendVPNPeriodMutation();
  const hideModal = useHideModal();
  const { data: session } = useGetUserSessionQuery();
  const [prices, setPrices] = useState<Array<{ months: number; totalPrice: number }>>([]);

  const [getOrderPrice, { isLoading }] = useGetOrderPriceMutation();
  const { isLegacyMobileProxiesEnabled } = useFeatureFlag();

  useEffect(() => {
    Promise.all(
      SUPPORTED_VPN_MONTHS.map(async (months) =>
        getOrderPrice(
          toOrderConfigurationPayload(
            { networkType: NetworkType.VPN, timePeriod: `${months}m` },
            isLegacyMobileProxiesEnabled,
          ),
        ),
      ),
    ).then((responsePrices) => {
      setPrices(
        SUPPORTED_VPN_MONTHS.map((months, index) => {
          const currentMonthPriceData = (responsePrices[index] as { data: OrderPriceModel }).data;

          return {
            months,
            totalPrice: currentMonthPriceData.subtotal,
          };
        }),
      );
    });
  }, [getOrderPrice, isLegacyMobileProxiesEnabled]);

  return (
    <Formik
      initialValues={{
        periodInMonths: 1,
      }}
      onSubmit={async ({ periodInMonths }) => {
        const foundPrice = prices?.find(({ months }) => months === periodInMonths);

        if (!foundPrice) {
          ToastManager.error(t('vpns.modals.extendPeriod.failure'));

          return;
        }

        try {
          await extendPeriod({ vpnId, periodInMonths }).unwrap();

          GA_EVENTS.vpnPeriodExtended({
            userId: session?.userId,
            value: foundPrice.totalPrice,
            vpnId,
            period: periodInMonths,
            networkType: NetworkType.VPN,
          });

          posthog?.capture(posthogEvents.vpn.extendPeriod.success, { vpnId, periodInMonths });

          ToastManager.success(t('vpns.modals.extendPeriod.success'));
          hideModal();
        } catch (error) {
          const err = error as AxiosBaseQueryError;

          if (err.errors && typeof err.errors === 'string') {
            ToastManager.error(t(`errors:${err.errors}`));
          } else {
            ToastManager.error(t('vpns.modals.extendPeriod.failure'));
          }

          posthog?.capture(posthogEvents.vpn.extendPeriod.failed, { vpnId, periodInMonths });
        }
      }}
    >
      {({ values }) => {
        const foundPrice = prices?.find(({ months }) => months === values.periodInMonths);

        return <ExtendVPNPeriodForm price={foundPrice?.totalPrice} isLoading={isLoading} />;
      }}
    </Formik>
  );
}

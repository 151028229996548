/* eslint-disable import/no-named-as-default-member */
// fix for old android phones
import 'polyfill-object.fromentries';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { BrowserTracing } from '@sentry/browser';
import { HttpClient as HttpClientIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import dayjsBetween from 'dayjs/plugin/isBetween';
import dayjsUTC from 'dayjs/plugin/utc';
import { AbortController } from 'node-abort-controller';
import type { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import { App } from 'App';
import { store } from 'store';
import { theme } from 'theme';

dayjs.extend(dayjsUTC);
dayjs.extend(dayjsDuration);
dayjs.extend(dayjsBetween);

// add polyphill for old browsers, like India UC Browser to prevent crashes
if (!global.AbortController) {
  global.AbortController = AbortController as typeof globalThis.AbortController;
}

if (process.env.REACT_APP_ENV === 'production') {
  if (process.env.REACT_APP_GTM_CONTAINER_ID) {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_CONTAINER_ID,
    });
  }

  if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
  }
}

const options: Partial<PostHogConfig> = {
  api_host: process.env.REACT_APP_POSTHOG_HOST,
  loaded: (posthog) => {
    // Disable PostHog in development and staging
    if (['development', 'staging'].includes(process.env.REACT_APP_ENV)) {
      posthog.opt_out_capturing();
    }
  },
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: { password: true },
    maskInputFn: (text, element) => {
      if (
        // @ts-expect-error - This is a proper way to get the field name
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        element?.attributes.name?.value?.toLowerCase()?.includes('password') ||
        // @ts-expect-error - This is a proper way to get the field name
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        element?.attributes.name?.value?.toLowerCase()?.includes('passwd')
      ) {
        return '*'.repeat(text.length);
      }

      return text;
    },
    maskTextSelector: '#password, #passwd, #passwd2, #newPassword, #newPasswordConfirmation',
  },
};

if (process.env.REACT_APP_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing(),
      new HttpClientIntegration({ failedRequestStatusCodes: [[500, 505], 507] }),
      // Remove Sentry wrapper for event handlers to avoid some issues
      // https://github.com/getsentry/sentry-javascript/issues/3040#issuecomment-913549441
      new Sentry.Integrations.TryCatch({ eventTarget: false }),
    ],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 1.0,
    debug: process.env.REACT_APP_ENV !== 'production',
    release: process.env.REACT_APP_SENTRY_RELEASE,

    // This options is required for capturing headers and cookies
    sendDefaultPii: true,
    beforeSend(event, hint) {
      const error = hint.originalException as string | { message?: string };
      if (error === 'Timeout') return null;

      const errorMessage = typeof error === 'string' ? error : error?.message;

      if (
        errorMessage?.match(/confirmPayment/g) ||
        errorMessage?.match(/confirmSetup/g) ||
        errorMessage?.match(/PaymentIntent/g) ||
        errorMessage?.match(/msDiscoverChatAvailable/g) ||
        // error caused by Clarity.js
        // TODO: Do we need to ignore those errors on our end? Shouldn't it be fixed on the SEO side?
        errorMessage?.match(/Cannot read properties of null \(reading 'version'\)/g) ||
        errorMessage?.match(/Cannot read properties of null \(reading 'sessionId'\)/g) ||
        // error caused by mobile devices on recent iphone, google chrome
        // does not break experience, need to monitor
        errorMessage?.match(/undefined is not an object \(evaluating 'a.O'\)/g) ||
        errorMessage?.match(/undefined is not an object \(evaluating 'a.N'\)/g) ||
        errorMessage?.match(/undefined is not an object \(evaluating 'a.M'\)/g) ||
        errorMessage?.match(/undefined is not an object \(evaluating 'a.L'\)/g) ||
        errorMessage?.match(/Illegal argument undefined/g) ||
        errorMessage?.match(/xbrowser is not defined/g) ||
        // error caused by mui null button ref
        errorMessage?.match(/Cannot read properties of null \(reading 'tagName'\)/g) ||
        errorMessage?.match(/null is not an object \(evaluating 'e.tagName'\)/g) ||
        // macos safari / ios issue with private mode
        errorMessage?.match(/null is not an object \(evaluating 'localStorage.getItem'\)/g) ||
        // Clarity related error (different devices - mostly Chrome Mobile and Android)
        errorMessage?.match(/Animation is not defined/g) ||
        errorMessage?.match(
          /Non-Error promise rejection captured with value: You don't have permissions. Reference number/g,
        ) ||
        // Fetch API used directly by chrome extensions and 3rd party code
        errorMessage?.match(/Failed to fetch/g) ||
        errorMessage?.match(/Talisman extension has not been configured yet/g) ||
        errorMessage?.match(/Cannot redefine property: googletag/g) ||
        errorMessage?.match(/window.requestIdleCallback/g)
      ) {
        return null;
      }

      // ignore BE validation errors
      if (
        event.breadcrumbs?.[1]?.category === 'xhr' &&
        (event.breadcrumbs[1]?.data?.status_code === 403 || !event.breadcrumbs[1]?.data?.status_code) &&
        (event.breadcrumbs[0]?.message?.match(/Non-Error promise rejection captured with keys/g) ||
          event.breadcrumbs[0]?.message?.match(/Object captured as promise rejection with keys/g))
      )
        return null;

      // any 3rd party request as current app requests category is xhr
      if (event.breadcrumbs?.[0]?.category === 'fetch' || event.breadcrumbs?.[1]?.category === 'fetch') return null;

      // ignore recent errors came after 401 response
      if (
        (event.breadcrumbs?.[0]?.category === 'xhr' && event.breadcrumbs[0]?.data?.status_code === 401) ||
        (event.breadcrumbs?.[1]?.category === 'xhr' && event.breadcrumbs[1]?.data?.status_code === 401)
      )
        return null;

      return event;
    },
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      'max action recursion depth reached',
      // error on Android 12 phone, making main script to load longer
      'Large Render Blocking Asset',
      // TODO: might want to improve this to avoid real issues. For now it causes by 3rd party extensions to block loading stripe script
      'Failed to load Stripe.js',
      '/posthog/',
      '/widget.js/',
      // popover error
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      // 3rd party scripts
      "undefined is not an object (evaluating 'a.R')",
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      /gstatic.com\/recaptcha/i,
      /gstatic.com\/recaptcha\/releases\/[a-zA-Z0-9]+\/recaptcha__[a-z]+_[a-z]+.js/,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // FreshChat
      /proxy-cheap-org-[a-z0-9]+.freshchat.com\/js\/widget.js/i,
      /eu\.fw-cdn\.com/i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      // Google Translate
      /translate\.googleapis\.com/i,
      // Cloudflare
      /static\.cloudflareinsights\.com/i,
      // PostHog
      /posthog/,
      // Hotjar injected
      /script\.hotjar\.com/i,
    ],
  });
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);

root.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <PostHogProvider apiKey={process.env.REACT_APP_POSTHOG_KEY} options={options}>
      <HelmetProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </Provider>
      </HelmetProvider>
    </PostHogProvider>
  </Sentry.ErrorBoundary>,
);

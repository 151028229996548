import { useCallback, useMemo } from 'react';

import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Icon } from 'components/Icon';
import { Logo } from 'components/Logo';
import { useHideModal } from 'components/modals/context';
import { GA_EVENTS } from 'constants/gaEvents';
import { posthogEvents } from 'constants/posthogEvents';
import { useIsNewCustomer } from 'hooks/useIsNewCustomer';
import { useRouter } from 'hooks/useRouter';
import { useShowModal } from 'modules/common/GlobalModals';
import { paths } from 'paths';
import { useAppDispatch, useAppSelector } from 'store';
import { useGetUserSessionQuery } from 'store/api';
import { resetOrderFlow, setCurrentStep } from 'store/orders/reducers';
import type { ITheme } from 'theme';

import { OrderLayoutNavigation } from './OrderLayoutNavigation';
import Styled from './styled';
import { useOrderFlow } from '../hooks/useOrderFlow';

export function OrderLayoutAppBar() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isMobileDevice = useMediaQuery<ITheme>((theme) => theme.breakpoints.down('md'));
  const router = useRouter();
  const showModal = useShowModal();
  const hideModal = useHideModal();
  const posthog = usePostHog();
  const { data: session } = useGetUserSessionQuery();
  const { couponCode, proxySettings, price, currentStep, selectedNetworkType } = useAppSelector((state) => state.order);

  const isNewCustomer = useIsNewCustomer();
  const { isMarketplaceFlow, isVPNFlow } = useOrderFlow();

  const onConfirm = useCallback(() => {
    dispatch(resetOrderFlow());

    GA_EVENTS.removeFromCart({
      userId: session?.userId,
      value: price?.finalPrice || 0,
      count: proxySettings?.quantity || 1,
      coupon: couponCode || undefined,
      networkType: proxySettings?.networkType ?? null,
    });

    posthog?.capture(posthogEvents.order.exit, { isMarketplaceFlow, isNewCustomer });

    hideModal();

    router.replace(isMarketplaceFlow ? paths.marketplace.index : paths.index);
  }, [
    dispatch,
    session?.userId,
    price?.finalPrice,
    proxySettings?.quantity,
    proxySettings?.networkType,
    couponCode,
    posthog,
    isMarketplaceFlow,
    isNewCustomer,
    hideModal,
    router,
  ]);

  const onBackClick = useCallback(() => {
    if (currentStep === 'proxy-setup') {
      return dispatch(resetOrderFlow());
    }

    if (isVPNFlow) {
      dispatch(setCurrentStep('vpn-plan'));
    }

    return dispatch(setCurrentStep(selectedNetworkType ? 'proxy-setup' : null));
  }, [currentStep, dispatch, isVPNFlow, selectedNetworkType]);

  const shouldDisplayBackArrow = useMemo(() => {
    if (!isMobileDevice) return false;

    return location.pathname !== paths.order.index;
  }, [isMobileDevice, location.pathname]);

  return (
    <Styled.OrderLayoutAppBarRoot>
      <Styled.OrderLayoutToolbar disableGutters>
        {shouldDisplayBackArrow && (
          <IconButton onClick={onBackClick}>
            <Icon name="arrow-left" />
          </IconButton>
        )}

        <Styled.LogoWrapper>
          <Link
            to={isMarketplaceFlow ? paths.marketplace.index : paths.index}
            onClick={(e) => {
              e.preventDefault();

              return showModal('orders.cancel', {
                title: t('common:order.modals.quit.title'),
                subtitle: t('common:order.modals.quit.subtitle'),
                onConfirm,
                confirmLabel: t('common:buttons.quit'),
              });
            }}
          >
            <Logo wide={!isMobileDevice} />
          </Link>
        </Styled.LogoWrapper>

        {!isMobileDevice && (
          <Styled.NavigationWrapper>
            <OrderLayoutNavigation />
          </Styled.NavigationWrapper>
        )}

        <Styled.IconButton
          onClick={() =>
            showModal('orders.cancel', {
              title: t('common:order.modals.quit.title'),
              subtitle: t('common:order.modals.quit.subtitle'),
              onConfirm,
              confirmLabel: t('common:buttons.quit'),
            })
          }
        >
          <Icon name="cross" />
        </Styled.IconButton>
      </Styled.OrderLayoutToolbar>
    </Styled.OrderLayoutAppBarRoot>
  );
}

import { Mutex } from 'async-mutex';

import { sequentialBaseQueryFactory, type AppEndpointBuilder } from 'store/base';
import type { Paginate } from 'types';

import type {
  BuyTrafficBulkParams,
  GlobalIPWhitelistPayload,
  HostnameIPResponse,
  IPWhitelistResponse,
  IpWhitelistPayload,
  ProxyExtendBandwidthParams,
  ProxyExtendPeriodBulkParams,
  ProxyExtendPeriodParams,
  ProxyExtensionPrice,
  ProxyISPsResponse,
  ProxyNoteParams,
  ProxyProtocolPayload,
  ProxyProtocolResponse,
  ProxyQueryParams,
  ProxyReactivateParams,
  ProxyRegenerateCredentialsPayload,
  ProxyResponse,
  UpgradeBandwidthSpeedParams,
  BandwidthSpeedDto,
  ChangeAuthenticationTypePayload,
  ProxyAuthenticationTypeResponse,
  UpgradeThreadsParams,
  ThreadsUpgradeDTO,
  ProxyBandwidthAutoExtendPayload,
  ProxyIdPayload,
  ProxyProtocolBulkParams,
  ChangeAuthenticationTypeBulkParams,
  DownloadProxyCredentialsParams,
  ProxyAdminPortChangeParams,
  ProxyAutoExtendBulkParams,
  DeleteProxyRoutePayload,
  AddProxyRoutePayload,
  RunDiagnosticRoutinePayload,
  ProxyReplacementOptionsDTO,
} from './dtos';
import {
  toBandwidthSpeedOptions,
  toBandwidthSpeedPrice,
  toDiagnosticRoutineModel,
  toMaintenanceStatus,
  toProxyAdminDetailsModel,
  toProxyChangelogModel,
  toProxyEventsModel,
  toProxyISPModel,
  toProxyModel,
  toProxyReplacementCheckModel,
  toProxyReplacementDetailsModel,
  toProxyReplacementOptionsModel,
  toProxySubnets,
  toProxySummaryModel,
  toThreadsOptions,
  toThreadsPrice,
} from './helpers';
import type {
  ProxyReplacementCheckModel,
  ProxyReplacementDetailsModel,
  BandwidthSpeedOptions,
  BandwidthSpeedPrice,
  BulkAutoExtendSettingsModel,
  DiagnosticRoutineModel,
  MaintenanceStatus,
  ProxyAdminDetailsModel,
  ProxyChangelogModel,
  ProxyEventModel,
  ProxyISP,
  ProxyModel,
  ProxySubnet,
  ProxySummaryModel,
  ThreadsChangeOptions,
  ThreadsUpgradePrice,
  ProxyReplacementOptionsModel,
} from './models';
import type { GetProxyReplacementOptionsPayload, ProxyReplacementPayload } from './payloads';

const mutex = new Mutex();

export const proxyEndpoints = (builder: AppEndpointBuilder) => ({
  getUserProxiesSummary: builder.query<ProxySummaryModel, void>({
    query: () => ({
      url: 'proxies/summary',
      method: 'GET',
    }),
    transformResponse: toProxySummaryModel,
    providesTags: ['Proxy-Summary'],
  }),

  getProxiesList: builder.query<Paginate<ProxyModel>, ProxyQueryParams>({
    query: (params) => ({
      url: 'services/proxies',
      method: 'GET',
      params,
    }),
    transformResponse: (response: Paginate<ProxyResponse>) => ({ ...response, data: response.data.map(toProxyModel) }),
    providesTags: (result) =>
      result?.data
        ? [...result.data.map(({ id }) => ({ type: 'Proxy' as const, id })), { type: 'Proxy', id: 'LIST' }]
        : [{ type: 'Proxy', id: 'LIST' }],
  }),

  getProxyOverview: builder.query<ProxyModel, number>({
    query: (proxyId) => ({
      url: `services/proxies/${proxyId}`,
      method: 'GET',
    }),
    transformResponse: toProxyModel,
    providesTags: (_, __, id) => [{ type: 'Proxy', id }],
  }),

  getProxyISPs: builder.query<ProxyISP[], void>({
    query: () => ({
      url: 'services/proxies/isp',
      method: 'GET',
    }),
    providesTags: (result) =>
      result?.map
        ? [...result.map(({ id }) => ({ type: 'Proxy-ISP' as const, id })), { type: 'Proxy-ISP', id: 'LIST' }]
        : [{ type: 'Proxy-ISP', id: 'LIST' }],

    transformResponse: (data: ProxyISPsResponse) => {
      return data.data.map(toProxyISPModel);
    },
  }),

  getPeriodExtensionPrice: builder.query<ProxyExtensionPrice, ProxyExtendPeriodParams>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/period-extension-price`,
      method: 'POST',
      data,
    }),
    providesTags: ['Extend-Period-Price'],
  }),

  getPeriodExtensionPriceBulk: builder.query<ProxyExtensionPrice, ProxyExtendPeriodBulkParams>({
    query: (data) => ({
      url: `proxies/bulk/extend-period/price`,
      method: 'POST',
      data,
    }),
    providesTags: ['Extend-Period-Price'],
  }),

  getBandwidthExtensionPrice: builder.query<ProxyExtensionPrice, ProxyExtendBandwidthParams>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/bandwidth-price`,
      method: 'POST',
      data,
    }),
    providesTags: ['Extend-Bandwidth-Price'],
  }),

  getBuyTrafficPriceBulk: builder.query<ProxyExtensionPrice, BuyTrafficBulkParams>({
    query: (data) => ({
      url: '/proxies/bulk/buy-traffic/price',
      method: 'POST',
      data,
    }),
    providesTags: ['Extend-Bandwidth-Price'],
  }),

  getReactivateProxyPrice: builder.query<ProxyExtensionPrice, ProxyReactivateParams>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/reactivate/price`,
      method: 'POST',
      data,
    }),
    providesTags: ['Reactivate-Proxy-Price'],
  }),

  getProxyIpWhitelist: builder.query<IPWhitelistResponse, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/whitelist-ip`,
      method: 'GET',
    }),
    providesTags: (_, __, id) => [{ type: 'Proxy-IPWhitelist', id }],
  }),

  getProxyProtocol: builder.query<ProxyProtocolResponse, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/change-protocol`,
      method: 'GET',
    }),
    providesTags: (_, __, id) => [{ type: 'Proxy-Protocol', id }],
  }),

  getGlobalIPWhitelistSettings: builder.query<IPWhitelistResponse, void>({
    query: () => ({
      url: 'proxies/settings/global-ip-whitelist',
      method: 'GET',
    }),
    providesTags: ['Proxy-Global-IP-Whitelist'],
  }),

  getHostnameIp: builder.query<HostnameIPResponse, number | string>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/credential-generator`,
      method: 'GET',
    }),
  }),

  getProxyAuthenticationType: builder.query<ProxyAuthenticationTypeResponse, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/change-authentication-type`,
      method: 'GET',
    }),
    providesTags: (_, __, id) => [{ type: 'Proxy-Auth-Type', id }],
  }),

  getBandwidthSpeedUpgradePrice: builder.query<BandwidthSpeedPrice[], ProxyResponse['id']>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/buy-uplink-speed`,
      method: 'GET',
    }),
    providesTags: ['Upgrade-Bandwidth-Speed-Price'],
    transformResponse: (data: BandwidthSpeedDto) => {
      return data.availableUplinkSpeeds.map(toBandwidthSpeedPrice);
    },
  }),

  getBandwidthSpeedChangeOptions: builder.query<BandwidthSpeedOptions, ProxyResponse['id']>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/change-uplink-speed`,
      method: 'GET',
    }),
    providesTags: ['Change-Bandwidth-Speed-Options'],
    transformResponse: toBandwidthSpeedOptions,
  }),

  getThreadsUpgradePrice: builder.query<ThreadsUpgradePrice[], ProxyResponse['id']>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/buy-threads`,
      method: 'GET',
    }),
    providesTags: ['Upgrade-Threads-Price'],
    transformResponse: (data: ThreadsUpgradeDTO) => {
      return data.availableThreads.map(toThreadsPrice);
    },
  }),

  getBulkAutoExtendSettings: builder.query<BulkAutoExtendSettingsModel, Array<ProxyResponse['id']>>({
    query: (proxyIds) => ({
      url: `proxies/bulk/auto-extend/details`,
      method: 'POST',
      data: { ids: proxyIds },
    }),
  }),

  getThreadsChangeOptions: builder.query<ThreadsChangeOptions, ProxyResponse['id']>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/change-threads`,
      method: 'GET',
    }),
    providesTags: ['Change-Threads-Options'],
    transformResponse: toThreadsOptions,
  }),

  extendProxyPeriod: builder.mutation<void, ProxyExtendPeriodParams>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/extend-period`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, params) => ['Proxy-Summary', 'Account-Balance', { type: 'Proxy', id: params.proxyId }],
  }),

  extendProxyPeriodBulk: builder.mutation<void, ProxyExtendPeriodBulkParams>({
    query: (data) => ({
      url: `proxies/bulk/extend-period`,
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Proxy-Summary', 'Account-Balance', 'Proxy'],
  }),

  autoExtendProxyBulk: builder.mutation<void, ProxyAutoExtendBulkParams>({
    query: (data) => ({
      url: `proxies/bulk/auto-extend`,
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Proxy-Summary', 'Account-Balance', 'Proxy', 'Proxy-Admin-Details'],
  }),

  extendProxyBandwidth: builder.mutation<void, ProxyExtendBandwidthParams>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/buy-bandwidth`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, params) => ['Proxy-Summary', 'Account-Balance', { type: 'Proxy', id: params.proxyId }],
  }),

  buyTrafficBulk: builder.mutation<void, BuyTrafficBulkParams>({
    query: (data) => ({
      url: '/proxies/bulk/buy-traffic',
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Proxy-Summary', 'Account-Balance', 'Proxy'],
  }),

  reactivateProxy: builder.mutation<void, ProxyReactivateParams>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/reactivate`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, params) => ['Proxy-Summary', 'Account-Balance', { type: 'Proxy', id: params.proxyId }],
  }),

  upsertProxyNote: builder.mutation<void, ProxyNoteParams>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/note`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, params) => [{ type: 'Proxy', id: params.proxyId }],
  }),

  enableProxyAutoExtend: builder.mutation<void, ProxyBandwidthAutoExtendPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/auto-extend/enable`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId }) => ['Extend-Period-Price', 'Account-Balance', { type: 'Proxy', id: proxyId }],
  }),

  disableProxyAutoExtend: builder.mutation<void, ProxyIdPayload>({
    query: ({ proxyId }) => ({
      url: `proxies/${proxyId}/auto-extend/disable`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, { proxyId }) => ['Extend-Period-Price', { type: 'Proxy', id: proxyId }],
  }),

  updateGlobalIPWhitelistSettings: builder.mutation<void, GlobalIPWhitelistPayload>({
    query: (data) => ({
      url: 'proxies/settings/global-ip-whitelist',
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Proxy-Global-IP-Whitelist'],
  }),

  updateIpWhitelist: builder.mutation<void, IpWhitelistPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/whitelist-ip`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, params) => [
      { type: 'Proxy', id: params.proxyId },
      { type: 'Proxy-IPWhitelist', id: params.proxyId },
      { type: 'Proxy-Protocol', id: params.proxyId },
      { type: 'Proxy-Auth-Type', id: params.proxyId },
    ],
  }),

  updateProxyProtocol: builder.mutation<void, ProxyProtocolPayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/change-protocol`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, params) => [
      { type: 'Proxy', id: params.proxyId },
      { type: 'Proxy-Protocol', id: params.proxyId },
    ],
  }),

  updateProxyProtocolBulk: builder.mutation<void, ProxyProtocolBulkParams>({
    query: (data) => ({
      url: `proxies/bulk/change-protocol`,
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Proxy-Summary', 'Proxy', 'Proxy-Protocol'],
  }),

  regenerateCredentials: builder.mutation<void, ProxyRegenerateCredentialsPayload>({
    query: ({ proxyId }) => ({
      url: `proxies/${proxyId}/regenerate-credentials`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, params) => [{ type: 'Proxy', id: params.proxyId }],
  }),

  upgradeBandwidthSpeed: builder.mutation<void, UpgradeBandwidthSpeedParams>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/buy-uplink-speed`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, params) => [
      'Proxy-Summary',
      'Account-Balance',
      'Upgrade-Bandwidth-Speed-Price',
      { type: 'Proxy', id: params.proxyId },
    ],
  }),

  changeBandwidthSpeed: builder.mutation<void, UpgradeBandwidthSpeedParams>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/change-uplink-speed`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, params) => [
      'Proxy-Summary',
      'Upgrade-Bandwidth-Speed-Price',
      { type: 'Proxy', id: params.proxyId },
    ],
  }),

  upgradeThreads: builder.mutation<void, UpgradeThreadsParams>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/buy-threads`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, params) => [
      'Proxy-Summary',
      'Account-Balance',
      'Upgrade-Threads-Price',
      { type: 'Proxy', id: params.proxyId },
    ],
  }),

  changeThreads: builder.mutation<void, UpgradeThreadsParams>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/change-threads`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, params) => [
      'Proxy-Summary',
      'Upgrade-Threads-Price',
      { type: 'Proxy', id: params.proxyId },
    ],
  }),

  updateAuthenticationType: builder.mutation<void, ChangeAuthenticationTypePayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/change-authentication-type`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, params) => [
      { type: 'Proxy', id: params.proxyId },
      { type: 'Proxy-IPWhitelist', id: params.proxyId },
      { type: 'Proxy-Protocol', id: params.proxyId },
      { type: 'Proxy-Auth-Type', id: params.proxyId },
    ],
  }),

  updateAuthenticationTypeBulk: builder.mutation<void, ChangeAuthenticationTypeBulkParams>({
    query: (data) => ({
      url: `proxies/bulk/change-authentication-type`,
      method: 'POST',
      data,
    }),
    invalidatesTags: ['Proxy-Summary', 'Proxy', 'Proxy-Auth-Type'],
  }),

  // ! ADMIN PANEL FOR PROXIES
  getProxyEvents: builder.query<ProxyEventModel[], number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/events`,
      method: 'GET',
    }),
    transformResponse: toProxyEventsModel,
    providesTags: ['Proxy-Admin-Events'],
  }),

  getProxyChangelog: builder.query<ProxyChangelogModel, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/change-history`,
      method: 'GET',
    }),
    transformResponse: toProxyChangelogModel,
    providesTags: ['Proxy-Admin-Change-History'],
  }),

  getProxyAdminDetails: builder.query<ProxyAdminDetailsModel, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/admin/details`,
      method: 'GET',
    }),
    transformResponse: toProxyAdminDetailsModel,
    providesTags: (_, __, id) => [{ type: 'Proxy-Admin-Details', id }],
  }),

  getProxySubnets: builder.query<ProxySubnet[], number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/admin/routes/details`,
      method: 'GET',
    }),
    transformResponse: toProxySubnets,
  }),

  addProxyRoute: builder.mutation<void, AddProxyRoutePayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/admin/routes`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, { proxyId }) => [
      { type: 'Proxy-Admin-Details', id: proxyId },
      { type: 'Proxy', id: proxyId },
    ],
  }),

  deleteProxyRoute: builder.mutation<void, DeleteProxyRoutePayload>({
    query: ({ proxyId, routeId }) => ({
      url: `proxies/${proxyId}/admin/routes/${routeId}`,
      method: 'DELETE',
    }),
    invalidatesTags: (_, __, { proxyId }) => [
      { type: 'Proxy-Admin-Details', id: proxyId },
      { type: 'Proxy', id: proxyId },
    ],
  }),

  runDiagnosticRoutine: builder.mutation<DiagnosticRoutineModel[], RunDiagnosticRoutinePayload>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/admin/diagnostic-routine`,
      method: 'POST',
      data,
    }),
    transformResponse: toDiagnosticRoutineModel,
  }),

  enableHttpsForProxy: builder.mutation<void, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/admin/https/enable`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, id) => [
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy', id },
    ],
  }),

  disableHttpsForProxy: builder.mutation<void, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/admin/https/disable`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, id) => [
      { type: 'Proxy-Admin-Details', id },
      { type: 'Proxy', id },
    ],
  }),

  initializeProxy: builder.mutation<void, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/initialize`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, params) => [{ type: 'Proxy', id: params }],
  }),

  activateProxy: builder.mutation<void, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/activate`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, params) => [{ type: 'Proxy', id: params }],
  }),

  cancelProxy: builder.mutation<void, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/cancel`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, params) => [{ type: 'Proxy', id: params }],
  }),

  expireProxy: builder.mutation<void, number>({
    query: (proxyId) => ({
      url: `proxies/${proxyId}/expire`,
      method: 'POST',
    }),
    invalidatesTags: (_, __, params) => [{ type: 'Proxy', id: params }],
  }),

  changeProxyPort: builder.mutation<void, ProxyAdminPortChangeParams>({
    query: ({ proxyId, ...data }) => ({
      url: `proxies/${proxyId}/admin/change-port`,
      method: 'POST',
      data,
    }),
    invalidatesTags: (_, __, params) => [
      { type: 'Proxy', id: params.proxyId },
      'Proxy-Admin-Details',
      'Proxy-Admin-Events',
      'Proxy-Admin-Change-History',
    ],
  }),

  getMaintenanceStatus: builder.query<MaintenanceStatus | null, void>({
    query: () => ({
      url: 'maintenance-windows',
      method: 'GET',
    }),
    providesTags: ['Maintenance-Windows'],
    transformResponse: toMaintenanceStatus,
  }),

  downloadCredentials: builder.mutation<string, DownloadProxyCredentialsParams>({
    query: (data) => ({
      url: 'proxies/bulk/download-credentials',
      method: 'POST',
      data,
    }),
  }),

  // #region Proxy Replacement
  getIsProxyReplacementAvailable: builder.query<ProxyReplacementCheckModel, void>({
    query: () => ({
      url: 'services/proxies/replacement/check',
      method: 'GET',
    }),
    providesTags: ['Proxy-Replacement-Status'],
    transformResponse: toProxyReplacementCheckModel,
  }),

  getReplaceableProxies: builder.query<ProxyReplacementDetailsModel[], void>({
    query: () => ({
      url: 'services/proxies/replacement/list',
      method: 'GET',
    }),
    providesTags: (result) =>
      result?.map
        ? [
            ...result.map(({ id }) => ({ type: 'Replaceable-Proxy' as const, id })),
            { type: 'Replaceable-Proxy', id: 'LIST' },
          ]
        : [{ type: 'Replaceable-Proxy', id: 'LIST' }],
    transformResponse: toProxyReplacementDetailsModel,
  }),

  replaceProxy: builder.mutation<unknown, ProxyReplacementPayload>({
    queryFn: async (data, api, extraOptions) => {
      return sequentialBaseQueryFactory(mutex)(
        { url: 'services/proxies/replacement/execute', method: 'POST', data },
        api,
        extraOptions,
      );
    },
    invalidatesTags: ['Proxy-Replacement-Status'],
    // invalidatesTags: (_, __, { proxyId }) => [
    //   { type: 'Replaceable-Proxy', id: proxyId },
    //   { type: 'Proxy', id: proxyId },
    // ],
  }),

  getProxyReplacementOptions: builder.mutation<ProxyReplacementOptionsModel, GetProxyReplacementOptionsPayload>({
    query: (data) => ({
      url: 'order/configuration',
      method: 'POST',
      params: { errorFormat: 'codes' },
      data,
    }),
    transformResponse: (data: ProxyReplacementOptionsDTO, _, { networkType, isUnusedProxy }) =>
      toProxyReplacementOptionsModel({ ...data, networkType, isUnused: isUnusedProxy }),
  }),
  // #endregion Proxy Replacement
});

import Drawer, { drawerClasses, type DrawerProps } from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

type SidebarProps = DrawerProps & {
  shouldDisplayAlertBanner?: boolean;
  offset?: number;
};

const Sidebar = styled(({ shouldDisplayAlertBanner, offset, ...props }: SidebarProps) => <Drawer {...props} />)(
  ({ shouldDisplayAlertBanner, offset = 0, theme }) => ({
    zIndex: theme.zIndex.appBar + 100,

    [`& .${drawerClasses.paper}`]: {
      backgroundColor: theme.palette.background.paper,
      width: 280,

      ...(shouldDisplayAlertBanner && { top: offset, transition: theme.transitions.create('top') }),

      [theme.breakpoints.down('md')]: {
        width: 296,
      },
    },
  }),
);

export default { Sidebar };

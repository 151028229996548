import { type ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import { Outlet } from 'react-router-dom';

import { CannyProvider } from 'components/Canny/CannyProvider';
import { FloatingButton } from 'components/FloatingButton';
import { Icon } from 'components/Icon';
import { useMaintenance } from 'hooks/useMaintenance';
import { useScreenSize } from 'hooks/useScreenSize';
import { useGetUserSessionQuery } from 'store/api';
import type { ITheme } from 'theme';

import { AppMaintenance } from './AppMaintenance';
import { AppNavbar } from './AppNavbar';
import { ReplacementBanner } from './ReplacementBanner';
import { Sidebar } from './Sidebar';
import Styled from './styled';

type DashboardLayoutProps = {
  children?: ReactNode;
};

export function AppLayout({ children }: DashboardLayoutProps) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { data: session } = useGetUserSessionQuery();

  const { width } = useScreenSize();

  const [offset, setOffset] = useState(0);
  const maintenanceRef = useRef<HTMLDivElement>(null);

  const isMobileDevice = useMediaQuery<ITheme>((theme) => theme.breakpoints.down('md'));

  const { isProxyReplacementAvailable, maintenance, shouldDisplayBanner } = useMaintenance();

  const isMaintenanceVisible = useMemo(() => {
    if (!maintenance) return false;

    if (!maintenance.end || !maintenance.start) return false;

    return true;
  }, [maintenance]);

  useEffect(() => {
    setOffset(maintenanceRef.current?.clientHeight ?? 0);
  }, [isMaintenanceVisible, width, isProxyReplacementAvailable]);

  return (
    <CannyProvider>
      <Styled.DashboardLayoutRoot shouldDisplayAlertBanner={shouldDisplayBanner} offset={offset}>
        <Styled.DashboardLayoutContentRoot>
          {isMobileDevice && isProxyReplacementAvailable && <ReplacementBanner />}
          {children || <Outlet />}
        </Styled.DashboardLayoutContentRoot>
      </Styled.DashboardLayoutRoot>

      <AppMaintenance
        ref={maintenanceRef}
        isVisible={shouldDisplayBanner}
        maintenance={maintenance}
        isReplacementAvailable={isProxyReplacementAvailable}
      />

      <AppNavbar shouldDisplayAlertBanner={shouldDisplayBanner} offset={offset} />

      <Sidebar
        offset={offset}
        isOpen={isSidebarOpen}
        shouldDisplayAlertBanner={shouldDisplayBanner}
        onClose={() => setIsSidebarOpen(false)}
      />

      {isMobileDevice && (
        <FloatingButton onClick={() => setIsSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen)}>
          <Styled.FloatingButtonBadge color="error" variant={session?.profileDetails?.isSuspended ? 'dot' : undefined}>
            <Icon name={isSidebarOpen ? 'cross' : 'menu'} iconStroke={(theme) => theme.palette.text.primary} />
          </Styled.FloatingButtonBadge>
        </FloatingButton>
      )}
    </CannyProvider>
  );
}

import { useCallback, useMemo } from 'react';

import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { posthogEvents } from 'constants/posthogEvents';
import { useShowModal } from 'modules/common/GlobalModals';
import { useGetUserSessionQuery, useResendEmailVerificationLinkMutation } from 'store/api';
import { ToastManager } from 'utils/toast';

export function useEmailVerification(withPolling = false) {
  const { t } = useTranslation();
  const posthog = usePostHog();
  const { data: session, isLoading } = useGetUserSessionQuery(undefined, {
    ...(withPolling && { pollingInterval: 5_000 }),
  });
  const showModal = useShowModal();
  const [resendEmailVerificationLink] = useResendEmailVerificationLinkMutation();

  const isEmailVerificationRequired = useMemo(
    () => (isLoading ? false : !session?.profileDetails?.isActive),
    [session, isLoading],
  );

  const shouldForceEmailVerification = useCallback(() => {
    if (!isEmailVerificationRequired) return false;

    showModal('account.email.verification');

    return true;
  }, [isEmailVerificationRequired, showModal]);

  const handleResendVerificationEmail = useCallback(async () => {
    try {
      await resendEmailVerificationLink().unwrap();
      posthog?.capture(posthogEvents.email.confirmation.resent, { email: session?.profileDetails?.email });
      ToastManager.success(t('account.modals.emailVerification.sent.success'));
    } catch {
      // noop
    }
  }, [resendEmailVerificationLink, t, posthog, session?.profileDetails?.email]);

  return useMemo(
    () => ({ isEmailVerificationRequired, session, handleResendVerificationEmail, shouldForceEmailVerification }),
    [handleResendVerificationEmail, isEmailVerificationRequired, session, shouldForceEmailVerification],
  );
}

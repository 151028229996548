import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTranslation } from 'react-i18next';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { CannyChangelogButton } from 'components/Canny/CannyChangelogButton';
import { Logo } from 'components/Logo';
import { paths } from 'paths';
import { useGetUserVPNQuery } from 'store/api';
import { VPNStatus } from 'store/vpns/dtos';
import type { ITheme } from 'theme';

import { AccountButton } from './AccountButton';
import Styled from './styled';
import { LanguageDropdown } from '../Language/LanguageDropdown';

type AppNavbarProps = {
  offset?: number;
  shouldDisplayAlertBanner: boolean;
};

export function AppNavbar({ shouldDisplayAlertBanner, offset }: AppNavbarProps) {
  const isMobileDevice = useMediaQuery<ITheme>((theme) => theme.breakpoints.down('md'));
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const { data, isLoading } = useGetUserVPNQuery();

  const shouldHideTitle = useMemo(() => {
    if (pathname.startsWith(paths.vpns.index) && isLoading) return true;

    return pathname.startsWith(paths.vpns.index) && data?.status !== VPNStatus.ACTIVE;
  }, [data?.status, isLoading, pathname]);

  const getPageTitle = () => {
    if (pathname === '/') return 'dashboardOverview';

    if (matchPath(`${paths.proxies.index}/:proxyId`, pathname)) {
      return 'proxyOverview';
    }

    return pathname.split('/').find(Boolean);
  };

  return (
    <Styled.DashboardNavbarRoot shouldDisplayAlertBanner={shouldDisplayAlertBanner} offset={offset}>
      <Styled.DashboardNavbarToolbar disableGutters>
        {isMobileDevice && (
          <Link to={paths.index}>
            <Logo wide={false} />
          </Link>
        )}

        <Typography variant={isMobileDevice ? 'title' : 'headline-2'} color="text.primary">
          {!shouldHideTitle && t(`titles.${getPageTitle()}`)}
        </Typography>

        <Box display="inline-flex" alignItems="center">
          <CannyChangelogButton />
          <LanguageDropdown iconOnly />
          <AccountButton />
        </Box>
      </Styled.DashboardNavbarToolbar>
    </Styled.DashboardNavbarRoot>
  );
}

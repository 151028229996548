/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { type ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import Box, { type BoxProps } from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import type { TableProps as MuiTableProps } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import type { TableCellProps } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as MoonIcon } from 'assets/icons/empty/moon.svg';
import { Result } from 'components/Result';

import { BulkActions, type BulkActionsType, type TableItemID } from './BulkActions';
import { Pagination, type PaginationProps as PageProps } from './Pagination';
import Styled from './styled';
import { type TableSort, TableSortableLabel } from './TableSortableLabel';

type RenderCellFunc<T> = (data: T, index: number) => ReactNode;

type ObjectLiteralWithID = {
  id?: TableItemID;
  [key: string]: unknown;
};

export type TableColumn<T> = {
  key: string;
  title?: string | null;
  align?: 'left' | 'right' | 'center';
  width?: TableCellProps['width'];
  maxWidth?: number;
  minWidth?: number;
  height?: TableCellProps['height'];
  sortable?: boolean;
  renderCell: string | RenderCellFunc<T>;
};

type TableProps<T> = MuiTableProps & {
  data: T[];

  bgcolor?: BoxProps['bgcolor'];

  /**
   * Columns definition
   */
  columns: Array<TableColumn<T>>;

  /**
   * Set of selected ids
   */
  selectedIds?: TableItemID[];

  /**
   * Key extractor for rows
   */
  keyExtractor?: (item: T) => string;

  /**
   * Determines the loading state
   */
  isLoading?: boolean;

  /**
   * Determines the fetching state
   */
  isFetching?: boolean;

  /**
   * Pagination parameters
   */
  PaginationProps?: PageProps;

  /**
   * Total items for all of the pages
   */
  total?: number;

  /**
   * Determines what to display when the data is empty
   */
  EmptyComponent?: ReactNode;

  /**
   * Determines what to display when processed data is empty
   * Useful for filter purposes - data needs to be empty, but total needs to be > 0
   */
  NotFoundSubtitle?: ReactNode;

  /**
   * Determines the visibility of checkboxes to select a row
   */
  selectable?: boolean;

  /**
   * Determines what bulk actions should be enabled for table
   */
  bulkActions?: BulkActionsType & { disabledIds?: TableItemID[] };

  /**
   * Handles the onSelected event to manage selected rows
   */
  onSelectedChange?: (ids: TableItemID[], isAllSelected: boolean) => void;

  /**
   * Assigns URL to the row item
   */
  getHref?: (item: T) => string;

  /**
   * Sorting props
   */
  defaultSortingKey?: string;
  onSort?: (sorter: TableSort<T>) => void;
};

type State<T extends ObjectLiteralWithID> = {
  selectedIds: TableItemID[];
  sorting: TableSort<T>;
  isAllSelected: boolean;
};

export function Table<T extends ObjectLiteralWithID>({
  data,
  columns,
  selectedIds,
  selectable,
  defaultSortingKey,
  PaginationProps,
  EmptyComponent,
  NotFoundSubtitle,
  getHref,
  onSort,
  isLoading,
  isFetching,
  total = 0,
  bulkActions,
  onSelectedChange,
  keyExtractor,
  ...props
}: TableProps<T>) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [state, setState] = useState<State<T>>({
    selectedIds: [],
    isAllSelected: false,
    sorting: columns
      .filter(({ sortable }) => !!sortable)
      .reduce((memo, { key }) => {
        memo[key as keyof T] = defaultSortingKey === key ? 'desc' : undefined;

        return memo;
        // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
      }, {} as TableSort<T>),
  });

  const selected = useMemo(() => {
    const isAnyItemSelected = state.selectedIds.length > 0;

    if (!selectable || !isAnyItemSelected) return { all: false, indeterminate: false };

    if (!PaginationProps || PaginationProps.perPage > total) {
      return {
        all: state.selectedIds.length === total,
        indeterminate: isAnyItemSelected && state.selectedIds.length < total,
      };
    }

    return {
      all: state.selectedIds.length === PaginationProps.perPage,
      indeterminate: isAnyItemSelected && state.selectedIds.length < PaginationProps.perPage,
    };
  }, [PaginationProps, selectable, state.selectedIds.length, total]);

  const isAllDisabled = useMemo(
    () => JSON.stringify(bulkActions?.disabledIds) === JSON.stringify(data.map((item) => item.id)),
    [bulkActions?.disabledIds, data],
  );

  const shouldRenderCheckbox = useCallback(
    (itemId?: TableItemID): itemId is TableItemID => {
      if (isLoading && selectable) return true;

      return !!selectable && !!itemId && !isAllDisabled;
    },
    [isAllDisabled, isLoading, selectable],
  );

  const onAllCheckboxChange = useCallback(() => {
    const items = data.map(({ id }) => id!);

    const isAllSelected = !state.isAllSelected ? items.length === total : false;
    const ids = !selected.all ? items : [];

    setState((prev) => ({ ...prev, isAllSelected, selectedIds: ids }));

    return onSelectedChange?.(ids, isAllSelected);
  }, [data, onSelectedChange, selected.all, state.isAllSelected, total]);

  const onItemSelect = useCallback(
    (itemId: TableItemID) => {
      const ids = state.selectedIds.includes(itemId)
        ? state.selectedIds.filter((id) => itemId !== id)
        : [...state.selectedIds, itemId];

      const isAllSelected = !state.isAllSelected ? ids.length === total : false;

      setState((prev) => ({ ...prev, isAllSelected, selectedIds: ids }));

      return onSelectedChange?.(ids, isAllSelected);
    },
    [onSelectedChange, state, total],
  );

  const onBulkActionsClose = useCallback(() => {
    setState((prev) => ({ ...prev, isAllSelected: false, selectedIds: [] }));

    return onSelectedChange?.([], false);
  }, [onSelectedChange]);

  const onBulkActionsAllSelect = useCallback(() => {
    setState((prev) => ({ ...prev, isAllSelected: true, selectedIds: data.map(({ id }) => id!) }));

    return onSelectedChange?.(
      data.map(({ id }) => id!),
      true,
    );
  }, [data, onSelectedChange]);

  useEffect(() => {
    onSort?.(state.sorting);
  }, [onSort, state.sorting]);

  useEffect(() => {
    if (!selectedIds) return;

    setState((prev) => ({ ...prev, selectedIds }));
  }, [selectedIds]);

  if (!isFetching && !data.length && !total && EmptyComponent) {
    return <>{EmptyComponent}</>;
  }

  if (!isFetching && !data.length && !!total && NotFoundSubtitle) {
    return <Result Icon={<MoonIcon />} title={t('common:noResultsFound')} subtitle={NotFoundSubtitle} />;
  }

  return (
    <Box>
      <Styled.TableWrapper>
        <Styled.Table {...props}>
          <TableHead>
            <Styled.TableRow>
              {shouldRenderCheckbox(data?.[0]?.id) && (
                <Styled.TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    disabled={isLoading || isAllDisabled}
                    checked={selected.all}
                    indeterminate={selected.indeterminate}
                    onChange={onAllCheckboxChange}
                  />
                </Styled.TableCell>
              )}

              {columns.map(({ key, title, align = 'left', sortable }) => (
                <Styled.TableCell key={key} align={align}>
                  {sortable ? (
                    <TableSortableLabel
                      name={key}
                      sort={state.sorting}
                      onChange={(sorting) => setState((prev) => ({ ...prev, sorting }))}
                    >
                      {title}
                    </TableSortableLabel>
                  ) : (
                    title
                  )}
                </Styled.TableCell>
              ))}
            </Styled.TableRow>
          </TableHead>

          <TableBody>
            {isFetching && !isLoading && (
              <Styled.TableRow>
                <Styled.TableCell colSpan={columns.length + (selectable ? 1 : 0)}>
                  <Styled.SpinnerContainer>
                    <CircularProgress size={64} />
                  </Styled.SpinnerContainer>
                </Styled.TableCell>
              </Styled.TableRow>
            )}

            {isLoading &&
              Array.from(Array(5).keys()).map((_, index) => (
                <Styled.TableRow key={index}>
                  {selectable && (
                    <Styled.TableCell padding="checkbox">
                      <Skeleton />
                    </Styled.TableCell>
                  )}

                  {columns.map(({ key }) => (
                    <Styled.TableCell key={key}>
                      <Skeleton />
                    </Styled.TableCell>
                  ))}
                </Styled.TableRow>
              ))}

            {!isFetching &&
              !isLoading &&
              data.map((item, index) => (
                <Styled.TableRow
                  key={item.id || keyExtractor?.(item)}
                  $clickable={!!getHref}
                  onClick={() => {
                    if (!getHref) return;

                    navigate(getHref(item));
                  }}
                >
                  {shouldRenderCheckbox(item.id) && (
                    <Styled.TableCell padding="checkbox">
                      {!bulkActions?.disabledIds?.includes(item.id) && (
                        <Checkbox
                          color="primary"
                          checked={state.selectedIds.includes(item.id)}
                          onClick={(e) => e.stopPropagation()}
                          disabled={bulkActions?.disabledIds?.includes(item.id)}
                          onChange={() => onItemSelect(item.id!)}
                        />
                      )}
                    </Styled.TableCell>
                  )}

                  {columns.map(({ key, align, renderCell, width, maxWidth, minWidth, height = width }) => (
                    <Styled.TableCell
                      key={key}
                      width={width}
                      height={height}
                      align={align}
                      sx={{ minWidth, maxWidth, textOverflow: 'ellipsis', overflow: 'hidden' }}
                    >
                      {getHref ? (
                        <Styled.CellLink to={getHref(item)}>
                          {typeof renderCell === 'function' ? renderCell(item, index) : renderCell}
                        </Styled.CellLink>
                      ) : (
                        <>{typeof renderCell === 'function' ? renderCell(item, index) : renderCell}</>
                      )}
                    </Styled.TableCell>
                  ))}
                </Styled.TableRow>
              ))}
          </TableBody>
        </Styled.Table>
      </Styled.TableWrapper>

      {PaginationProps && <Pagination {...PaginationProps} disabled={isFetching} />}

      {bulkActions && (
        <BulkActions
          ids={state.selectedIds}
          total={total}
          {...bulkActions}
          isOpen={selected.all || selected.indeterminate || state.selectedIds.length > 0}
          isAllSelected={state.isAllSelected}
          onCloseClick={onBulkActionsClose}
          onSelectAll={onBulkActionsAllSelect}
        />
      )}
    </Box>
  );
}

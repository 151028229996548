import { useMemo } from 'react';

import { useGetExperimentsQuery } from 'store/api';
import type { Experiment } from 'store/common/types';

export function useExperiment(name: Experiment) {
  const { data: experiments, isLoading } = useGetExperimentsQuery();

  const isEnabled = useMemo(() => {
    if (!experiments) return false;

    return experiments.findIndex(({ id }) => id === name) !== -1;
  }, [experiments, name]);

  const configuration = useMemo(() => {
    if (!experiments) return null;

    const foundExperiment = experiments.find(({ id }) => id === name);

    if (!foundExperiment) return null;

    if ('configuration' in foundExperiment) return foundExperiment.configuration;

    return null;
  }, [experiments, name]);

  return useMemo(
    () => ({ isExperimentLoading: isLoading, isEnabled, configuration }),
    [configuration, isEnabled, isLoading],
  );
}

import { createSlice } from '@reduxjs/toolkit';

import { api } from 'store/api';

import type { ProxiesState } from './types';

const initialState: ProxiesState = {
  replaceProgress: 0,
  proxiesWithErrors: [],
  replacementErrors: null,
};

const slice = createSlice({
  name: 'proxies',
  initialState,
  reducers: {
    resetReplaceProgress: (state) => {
      state.replaceProgress = 1;
    },
    resetProxiesWithErrors: (state) => {
      state.proxiesWithErrors = [];
    },

    resetReplacementErrors: (state) => {
      state.replacementErrors = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(api.endpoints.replaceProxy.matchFulfilled, (state) => {
      state.replaceProgress++;
    });

    builder.addMatcher(
      api.endpoints.replaceProxy.matchRejected,
      (
        state,
        {
          payload,
          meta: {
            arg: {
              originalArgs: { proxyId },
            },
          },
        },
      ) => {
        state.replaceProgress++;

        state.proxiesWithErrors = [...state.proxiesWithErrors, proxyId];

        state.replacementErrors = { ...state.replacementErrors, [proxyId]: payload };
      },
    );
  },
});

export const { resetReplaceProgress, resetProxiesWithErrors } = slice.actions;

export const { reducer: proxiesReducer } = slice;

import { useEffect, useMemo, useState } from 'react';

export function useDebounce<T>(value: T, timeout = 300) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), timeout);

    return () => {
      clearTimeout(timer);
    };
  }, [value, timeout]);

  return useMemo(() => debouncedValue, [debouncedValue]);
}

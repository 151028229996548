import type { SupportedCountry, SupportedISP } from 'store/orders/dtos';
import type { Nullable, PaginationParams, SortOrder } from 'types';

import type {
  AuthenticationType,
  IPVersion,
  ISP,
  NetworkType,
  Protocol,
  ProxyCredentialsFormat,
  ProxyPropositionAlias,
  ProxySubnetStatus,
} from './types';

export type ProxyIdPayload = {
  proxyId: ProxyResponse['id'];
};

export type ProxyIdsPayload = {
  ids: Array<ProxyResponse['id']>;
};

// #region Response

/**
 * @response
 *
 * GET /proxies/summary
 */
export type ProxySummaryResponse = {
  activeProxyCount: number;
  inactiveProxyCount: number;
  expiringProxyCount: number;
};

/**
 * @response
 *
 * GET /services/proxies
 */
export type ProxyResponseAuthentication = {
  whitelistedIps: string[];
  username: string | null;
  password: string | null;
};

export type ProxyResponseBandwidth = {
  total: number | null;
  used: number | null;
  updatedAt: string;
};

export type ProxyResponseConnection = {
  publicIp: string | null;
  connectIp: string | null;
  lastIp: string | null;
  ipVersion: IPVersion;
  httpPort: number | null;
  httpsPort: number | null;
  socks5Port: number | null;
  hostnames: Record<string, string>;
};

export type ProxyResponseLocation = {
  countryCode: string;
  regionCode: string | null;
};

export type ProxyResponseMetadata = {
  ispName: string | null;
};

export enum ProxyResponseStatus {
  PENDING = 'PENDING',
  INITIATING = 'INITIATING',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
}

export enum ProxyResponseAllowedAction {
  EXTEND_PERIOD = 'EXTEND_PERIOD',
  REACTIVATE = 'REACTIVATE',
  BUY_TRAFFIC = 'BUY_TRAFFIC',
  BUY_UPLINK_SPEED = 'BUY_UPLINK_SPEED',
  BUY_THREADS = 'BUY_THREADS',
  CHANGE_UPLINK_SPEED = 'CHANGE_UPLINK_SPEED', // used by managers
  CHANGE_THREADS = 'CHANGE_THREADS', // used by managers
  GENERATE_CREDENTIALS = 'GENERATE_CREDENTIALS',
  REGENERATE_CREDENTIALS = 'REGENERATE_CREDENTIALS',
  CHANGE_PROTOCOL = 'CHANGE_PROTOCOL',
  CHANGE_AUTHENTICATION_METHOD = 'CHANGE_AUTHENTICATION_METHOD',
  CHANGE_WHITELIST_IP = 'CHANGE_WHITELIST_IP',
  AUTO_EXTEND = 'AUTO_EXTEND',
  INITIALIZE = 'INITIALIZE',
  ACTIVATE = 'ACTIVATE',
  CANCEL = 'CANCEL',
  EXPIRE = 'EXPIRE',
  LIST_EVENTS = 'LIST_EVENTS',
  LIST_HISTORY_CHANGES = 'LIST_HISTORY_CHANGES',
  ADMIN_DETAILS = 'ADMIN_DETAILS',
  DOWNLOAD_CREDENTIALS = 'DOWNLOAD_CREDENTIALS',
  RUN_DIAGNOSTIC_ROUTINE = 'RUN_DIAGNOSTIC_ROUTINE',
}

export type ProxyResponse = {
  id: number;
  status: ProxyResponseStatus;
  networkType: NetworkType;
  authentication: ProxyResponseAuthentication;
  connection: ProxyResponseConnection;
  proxyType: Protocol;
  createdAt: string;
  expiresAt: string | null;
  metadata: ProxyResponseMetadata;
  bandwidth: ProxyResponseBandwidth;
  location: ProxyResponseLocation;
  note: string | null;
  activatedAt: string;
  updatedAt: string;
  autoExtendEnabled: boolean;
  autoExtendBandwidthAmount: number | null;
  actions: ProxyResponseAllowedAction[];
  uplinkSpeed?: {
    value: number;
    label: string;
  };
  threads?: {
    value: number;
    label: string;
  };
  maintenanceWindows: MaintenanceWindowDTO[];
  routes: ProxyRoute[];
  isReplaceable: boolean;
};
// # endregion

export type ProxyProtocolResponse = {
  currentType: Protocol;
  availableTypes: Protocol[];
};

export type ProxyAuthenticationTypeResponse = {
  currentAuthenticationType: AuthenticationType;
  availableAuthenticationTypes: AuthenticationType[];
};

export type ProxyISPsResponse = {
  data: ISP[];
};

export type ProxyQueryParams = PaginationParams & {
  preset?: 'ACTIVE' | 'EXPIRING' | 'INACTIVE';
  publicIp?: string;
  proxyType?: string[];
  ipVersion?: string;
  connectionType?: string;
  authenticationType?: string;
  status?: string[];
  countryCode?: string;
  ispName?: string;
  order?: {
    bandwidth?: SortOrder;
    expiresAt?: SortOrder;
    createdAt?: SortOrder;
  };
};

export type ProxyExtendPeriodParams = ProxyIdPayload & {
  periodInMonths: number;
};

export type UpgradeBandwidthSpeedParams = ProxyIdPayload & {
  uplinkSpeed: number;
};

export type UpgradeThreadsParams = ProxyIdPayload & {
  threads: number;
};

export type ProxyExtendPeriodBulkParams = ProxyIdsPayload & {
  periodInMonths: number;
};

export type ProxyAutoExtendBulkParams = ProxyIdsPayload & {
  bandwidth?: number;
  isEnabled: boolean;
};

export type ProxyExtensionPrice = {
  finalPrice: number;
  priceNoDiscounts: number;
  discount: number;
  unitPrice: number;
  additionalAmountAfterDiscount: number;
};

type AvailableItem = {
  id: number;
  name: string;
  totalPrice: string;
  unitPrice: string;
};

export type BandwidthSpeedDto = {
  currentUplinkSpeed: number;
  availableUplinkSpeeds: AvailableItem[];
};

export type ThreadsUpgradeDTO = {
  currentThreads: number;
  availableThreads: AvailableItem[];
};

export type ProxyExtendBandwidthParams = ProxyIdPayload & {
  bandwidth: number;
};

export type BuyTrafficBulkParams = ProxyIdsPayload & {
  trafficInGB: number;
};

export type ProxyReactivateParams = ProxyIdPayload & {
  periodInMonths: number;
};

export type ProxyNoteParams = ProxyIdPayload & {
  note: string | null;
};

export type IPWhitelistResponse = {
  whitelistedIps: string[];
  allowedWhitelistIpCount: number;
};

export type GlobalIPWhitelistPayload = {
  ips: string[];
};

export type HostnameIPResponse = {
  hostnameIp: Nullable<string>;
  alias: ProxyPropositionAlias;
};

export type IpWhitelistPayload = ProxyIdPayload & {
  ips: string[];
};

export type ProxyBandwidthAutoExtendPayload = ProxyIdPayload & {
  bandwidth?: number;
};

export type ChangeAuthenticationTypePayload = ProxyIdPayload & {
  newAuthType: AuthenticationType;
  ips: string[];
};

export type ChangeAuthenticationTypeBulkParams = ProxyIdsPayload & {
  authType: AuthenticationType;
  ips: string[];
};

export type ProxyProtocolPayload = ProxyIdPayload & {
  newType: string;
};

export type ProxyProtocolBulkParams = ProxyIdsPayload & {
  protocol: string;
};

export type ProxyRegenerateCredentialsPayload = ProxyIdPayload;

export type ProxyEventDTO = {
  createdAt: string;
  message: string;
};

export type ProxyEventsResponse = ProxyEventDTO[][];

/**
 * @response
 *
 * GET /proxies/{id}/events
 */

export type ProxyChangelogItemDTO = {
  fieldName: string;
  oldValue: string;
  newValue: string;
  createdAt: string;
  changedBy: string;
  userId: number;
};

export type ProxyChangelogResponse = ProxyChangelogItemDTO[];

/**
 * @response
 *
 * GET /proxies/{id}/admin-details
 */

export type ProxyAdminServerDTO = {
  id: string;
  name: string;
  ip: string;
};

export type ProxyAdminSubnetDTO = {
  id: string;
  subnetCidr: string;
  status: ProxySubnetStatus;
};

export type ProxyAdminVendorDTO = {
  urlText: string;
  url: string;
};

export type ProxyAdminPricesDTO = {
  unitPrice: string | null;
  totalValue: string | null;
};

export type ProxyAdminDetailsResponse = {
  userName: string;
  userId: number;
  proposition: string | null;
  reservedIp: string | null;
  server: ProxyAdminServerDTO | null;
  subnet: ProxyAdminSubnetDTO | null;
  isUsed: boolean;
  isHttpsProtocolSupported: boolean;
  vendor: ProxyAdminVendorDTO[];
  prices: ProxyAdminPricesDTO | null;
  routes: ProxyRoute[];
};

export type ProxyAdminPortChangeParams = {
  proxyId: number;
  port?: number;
  reason?: string;
};

export type MaintenanceWindowDTO = {
  id: string;
  startAt: string;
  endAt: string;
  note: string | null;
  replacementNote: string | null;
};

export type DownloadProxyCredentialsParams = {
  ids: number[];
  format: ProxyCredentialsFormat;
};

export type BulkAutoExtendSettingsDTO = {
  isBandwidthRequired: boolean;
  isAutoExtendEnabled: boolean;
};

export type ProxySubnetDTO = {
  id: string;
  cidr: string;
  serverName: string;
  countryCode: string;
};

export type ProxyRoute = {
  id: number;
  proxyService: number;
  server: string;
  publicIp: string;
  port: number;
};

export type AddProxyRoutePayload = ProxyIdPayload & {
  port: number;
  subnetId: string;
};

export type DeleteProxyRoutePayload = ProxyIdPayload & {
  routeId: number;
};

export type RunDiagnosticRoutinePayload = ProxyIdPayload & {
  url?: string;
};

export type DiagnosticRoutineResponse = {
  url: string;
  statusCode: number;
  statusMessage: string;
  time: number;
  metadata: Record<string, string>;
};

/**
 * @GET /services/proxies/replacement/check
 */
export type ProxyReplacementCheckDTO = {
  isReplacementAvailable: boolean;
};

/**
 * @GET /services/proxies/replacement/list
 */
export type ProxyReplacementDetailsDTO = {
  id: number;
  status: ProxyResponseStatus;
  networkType: NetworkType;
  publicIp: string;
  createdAt: string;
  expiresAt: string;
  ispId: string;
  countryCode: string;
  note: string | null;
  isUnusedProxy: boolean;
};

/**
 * @POST /order/configuration
 */
export type ProxyReplacementOptionsDTO = {
  isUnused: boolean;
  supportedCountries: SupportedCountry[];
  supportedISPs: [] | Record<string, [] | Record<string, SupportedISP>>;
};

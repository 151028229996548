import { forwardRef, useMemo } from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import type { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/Button';
import { Icon } from 'components/Icon';
import { RouterLink } from 'components/RouterLink';
import { paths } from 'paths';
import type { MaintenanceStatus } from 'store/proxies/models';

type AppMaintenanceProps = {
  isVisible: boolean;
  maintenance?: MaintenanceStatus | null;
  isReplacementAvailable?: boolean;
};

export const AppMaintenance = forwardRef<HTMLDivElement, AppMaintenanceProps>(
  ({ isVisible, maintenance, isReplacementAvailable }, ref) => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

    const date = useMemo(() => {
      if (!maintenance) return '';

      const start = dayjs(maintenance.start);
      const end = dayjs(maintenance.end);

      return `${start.format('YYYY-MM-DD HH:mm')} - ${end.format('YYYY-MM-DD HH:mm')}`;
    }, [maintenance]);

    const { title, subtitle, action } = useMemo(() => {
      if (isReplacementAvailable) {
        return {
          title: t('common:replacement.title'),
          subtitle: t('common:replacement.subtitle'),
          action: (
            <Button component={RouterLink} href={paths.proxies.replace} size="medium">
              {t('common:replacement.action')}
            </Button>
          ),
        };
      }

      return {
        title: t('common:maintenance.title'),
        subtitle: t('common:maintenance.subtitle', { date }),
      };
    }, [date, isReplacementAvailable, t]);

    if (isMobile && isReplacementAvailable) return null;

    return (
      <Box position="fixed" top={0} right={0} left={0}>
        <Collapse in={isVisible} mountOnEnter unmountOnExit>
          <Stack
            ref={ref}
            direction={{ xs: 'column', lg: 'row' }}
            spacing={{ xs: 2, lg: 0 }}
            alignItems="center"
            justifyContent={{ xs: 'center', lg: 'space-between' }}
            bgcolor="#080A0C"
            p={2}
          >
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              spacing={2}
              alignItems="center"
              justifyContent={{ xs: 'center', lg: 'flex-start' }}
            >
              <Icon name="danger-dark" />

              <Stack textAlign={{ xs: 'center', lg: 'start' }}>
                <Typography component="span" variant="title" color="#DFE3E6">
                  {title}
                </Typography>

                <Typography
                  component="span"
                  variant="body-1"
                  fontSize={{ xs: '14px', lg: '16px' }}
                  lineHeight={{ xs: '20px', lg: '24px' }}
                  color="#DFE3E6"
                >
                  {subtitle}
                </Typography>
              </Stack>
            </Stack>

            {action}
          </Stack>
        </Collapse>
      </Box>
    );
  },
);

import { type MouseEvent, useCallback } from 'react';

import { usePostHog } from 'posthog-js/react';

import { posthogEvents } from 'constants/posthogEvents';
import { useLogoutMutation } from 'store/api';

export function useSignOut() {
  const posthog = usePostHog();
  const [logout] = useLogoutMutation();

  return useCallback(
    async (e?: MouseEvent<HTMLElement>, shouldCapture = true) => {
      e?.preventDefault();

      if (shouldCapture) {
        posthog?.capture(posthogEvents.auth.signOut);
      }

      posthog?.reset();

      await logout().unwrap();
    },
    [logout, posthog],
  );
}

import { base } from 'store/base';

import { accountEndpoints } from './accounts/services';
import { authEndpoints } from './auth/services';
import { commonEndpoints } from './common/services';
import { marketplaceEndpoints } from './marketplace/services';
import { orderEndpoints } from './orders/services';
import { proxyEndpoints } from './proxies/services';
import { referralsEndpoints } from './referrals/services';
import { vpnEndpoints } from './vpns/services';

export const api = base.injectEndpoints({
  endpoints: (builder) => ({
    ...accountEndpoints(builder),
    ...authEndpoints(builder),
    ...commonEndpoints(builder),
    ...marketplaceEndpoints(builder),
    ...orderEndpoints(builder),
    ...proxyEndpoints(builder),
    ...referralsEndpoints(builder),
    ...vpnEndpoints(builder),
  }),
});

export const {
  // Account
  useGetUserAccountBalanceQuery,
  useGetUserAccountProfileQuery,
  useGetIDVerificationTokenQuery,
  useSaveAccountProfileMutation,
  useChangePasswordMutation,
  useGetNotificationPreferencesQuery,
  useSetNotificationPreferencesMutation,

  useGet2FAAuthenticationMethodsStatusesQuery,
  useEnable2FAToTPMutation,
  useDisable2FAToTPMutation,
  useEnable2FAWebAuthnMutation,
  useDisable2FAWebauthnMutation,

  useGetCreditCardDetailsQuery,
  useGetInvoicesQuery,
  useBulkDownloadInvoicesMutation,
  useGetTransactionsQuery,
  useGetBillingPreferencesQuery,
  useRemoveCreditCardMutation,
  useUpdateAutoTopUpPreferencesMutation,
  useAddCreditCardMutation,

  useTopUpMutation,
  useChangeLanguageMutation,
  useGetTopUpFeesMutation,
  useGetTopUpQuery,

  useLazyGetBillingPreferencesQuery,
  useLazyGetCreditCardDetailsQuery,
  useSkipProfileDetailsMutation,

  useDeleteApiKeyMutation,
  useGenerateApiKeyMutation,
  useGetApiKeysQuery,
  useResendEmailVerificationLinkMutation,

  // Auth
  useLoginMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useMultiFactorVerificationMutation,
  useGetMultiFactorStatusQuery,
  useLazyGetMultiFactorStatusQuery,
  useGetCaptchaQuery,
  useLogoutMutation,

  useGetUserSessionQuery,

  // App
  useGetMaintenanceStatusQuery,

  // Common
  useGetExperimentsQuery,
  useGetFeaturesQuery,

  // Marketplace
  useGetMarketplaceDealsQuery,
  useGetMarketplaceAvailableCountriesQuery,

  // Order
  useGetOrderConfigurationMutation,
  useGetOrderPriceMutation,
  useExecuteOrderMutation,
  useGetOrderQuery,

  // Proxy
  useGetUserProxiesSummaryQuery,
  useGetProxiesListQuery,
  useGetProxyISPsQuery,
  useExtendProxyPeriodMutation,
  useExtendProxyPeriodBulkMutation,
  useGetPeriodExtensionPriceQuery,
  useGetPeriodExtensionPriceBulkQuery,
  useExtendProxyBandwidthMutation,
  useBuyTrafficBulkMutation,
  useGetBandwidthExtensionPriceQuery,
  useGetBuyTrafficPriceBulkQuery,
  useEnableProxyAutoExtendMutation,
  useDisableProxyAutoExtendMutation,
  useUpsertProxyNoteMutation,
  useGetProxyOverviewQuery,

  useGetGlobalIPWhitelistSettingsQuery,
  useUpdateGlobalIPWhitelistSettingsMutation,
  useUpdateIpWhitelistMutation,

  useGetProxyIpWhitelistQuery,
  useGetReactivateProxyPriceQuery,
  useReactivateProxyMutation,

  useGetHostnameIpQuery,

  useGetProxyProtocolQuery,
  useUpdateProxyProtocolMutation,
  useUpdateProxyProtocolBulkMutation,
  useRegenerateCredentialsMutation,

  useGetBandwidthSpeedUpgradePriceQuery,
  useUpgradeBandwidthSpeedMutation,

  useGetThreadsUpgradePriceQuery,
  useUpgradeThreadsMutation,

  useGetProxyAuthenticationTypeQuery,
  useUpdateAuthenticationTypeMutation,
  useUpdateAuthenticationTypeBulkMutation,

  useGetBandwidthSpeedChangeOptionsQuery,
  useChangeBandwidthSpeedMutation,
  useGetThreadsChangeOptionsQuery,
  useChangeThreadsMutation,
  useDownloadCredentialsMutation,

  useGetBulkAutoExtendSettingsQuery,
  useAutoExtendProxyBulkMutation,

  // Proxy - Admin
  useActivateProxyMutation,
  useCancelProxyMutation,
  useExpireProxyMutation,
  useInitializeProxyMutation,
  useChangeProxyPortMutation,
  useGetProxyAdminDetailsQuery,
  useGetProxyChangelogQuery,
  useGetProxyEventsQuery,
  useEnableHttpsForProxyMutation,
  useDisableHttpsForProxyMutation,

  useAddProxyRouteMutation,
  useDeleteProxyRouteMutation,
  useGetProxySubnetsQuery,

  useRunDiagnosticRoutineMutation,

  // Proxy - Replacement
  useGetIsProxyReplacementAvailableQuery,
  useGetReplaceableProxiesQuery,
  useLazyGetReplaceableProxiesQuery,
  useReplaceProxyMutation,
  useGetProxyReplacementOptionsMutation,

  // Referrals
  useGetReferralsDetailsQuery,
  useGetReferredUsersQuery,
  useGetReferralTransactionsQuery,
  useGetReferralPayoutRequestsQuery,
  useGetBankDetailsQuery,
  useEnrollReferralsMutation,
  useUpsertBankDetailsMutation,
  useReferralsPayoutMutation,

  // VPNs
  useGetUserVPNQuery,
  useGetSupportedVPNLocationsQuery,
  useGetSupportedVPNPortsQuery,
  useDownloadOpenVPNConfigurationMutation,
  useDownloadWireGuardConfigurationMutation,
  useExtendVPNPeriodMutation,
  useEnableVPNAutoExtendMutation,
  useDisableVPNAutoExtendMutation,

  useGetVPNAdminDetailsQuery,
  useGetVPNChangelogQuery,
  useGetVPNEventsQuery,
  useCancelVPNMutation,
  useExpireVPNMutation,
  useReactivateVPNMutation,
} = api;
